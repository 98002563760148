import {
  IonButton, IonButtons,
  IonCol, IonDatetime, IonGrid, IonIcon, IonInput,
  IonItem, IonItemOption, IonItemOptions, IonItemSliding, IonLabel,
  IonList, IonRow,
  IonSelect,
  IonSelectOption, IonToolbar,
} from '@ionic/react';
import React, { useEffect, useState } from 'react';
import { connect } from '../data/connect';
import './ScheduleForm.scss';
import { Transfert } from '../models/Transfert';
import { add, trash } from 'ionicons/icons';
import { getTransferts } from '../data/selectors';
import { fetchTransferts } from '../data/agency/agency.actions';
import moment from "moment";

interface TransfertListFormProps {
  value: Transfert[];
  onChange: (data: any) => any;
  readonly: boolean;
}

interface StateProps {
  transferts: Transfert[];
}

interface DispatchProps {
  fetchTransferts: typeof fetchTransferts;
}

const TransfertListForm: React.FC<TransfertListFormProps & DispatchProps & StateProps> = ({ value, onChange, readonly, transferts }) => {

  const form = value || [];
  const setForm = (data: any) => onChange(data);

  const interfaceOpts = {
    header: 'Zone de transfert',
    subHeader: transferts.length ? 'Sélectionner votre zone de transfert' : 'Aucune zone de transfert',
  };

  const setValue = (key: number, data: Partial<Transfert>) => {
    const newForm = form.map((a, i) => ({
      ...a,
      ...i === key ? data : {}
    }));
    setForm(newForm);
  };

  const removeTransfert = (index: number) => {
    const newForm = form.filter((_, i) => i !== index);
    setForm(newForm);
  };

  if (!transferts.length) {
    return <IonList><p className="ion-padding-start">Aucune zones de transfert pour cette agence</p></IonList>;
  }

  return (
    <>
      { form.length > 0 &&
      <IonList>
        { form.map((transfert, i) => <IonItemSliding key={`transfert-${transfert.id_transfert}-${i}`}>
          <IonItemOptions side="end">
            <IonItemOption icon-only color={'danger'} onClick={() => removeTransfert(i)}>
              <IonIcon slot="icon-only" icon={trash} />
            </IonItemOption>
          </IonItemOptions>
          <IonGrid>
            <IonRow>
              <IonCol size="6" class="timeHeader">
                <p>Désignation</p>
              </IonCol>
              <IonCol size="6" class="timeHeader">
                <p>Date</p>
              </IonCol>
            </IonRow>
          </IonGrid>
          <IonItem>
            <IonGrid>
              <IonRow>
                <IonCol class="timeColumn" size="6">
                  { !readonly && <IonSelect
                    onIonChange={(e) => setValue(i, { id_transfert: e.detail.value })}
                    style={{ maxWidth: 'fit-content' }}
                    placeholder="Zone de transfert"
                    value={Number(transfert.id_transfert)}
                    interfaceOptions={interfaceOpts}
                    cancelText="Annuler"
                  >
                    {transferts.map(transfert => <IonSelectOption key={`transfert-${transfert.id}`} value={transfert.id}>{transfert.name}</IonSelectOption>)}
                  </IonSelect>
                  }
                  {
                    readonly &&
                    <IonItem>
                      <IonLabel class="ion-text-center">
                        {(transferts.find(a => a.id === Number(transfert.id_transfert)) || { name: '' }).name}
                      </IonLabel>
                    </IonItem>
                  }
                </IonCol>
                <IonCol class="timeColumn" size="6">
                  <IonDatetime
                    cancelText="annuler"
                    doneText="confirmer"
                    readonly={readonly}
                    style={{ paddingLeft: 5 }}
                    placeholder={'22/02/2020'}
                    displayFormat="D/MM/YYYY"
                    pickerFormat="D MMM YYYY"
                    monthShortNames="jan, fev, mar, avr, mai, juin, juil, aoû, sep, oct, nov, dec"
                    value={transfert.date} onIonChange={(e) => {
                      const time = e.detail.value!;
                      setValue(i, { date: moment(time).format('YYYY-MM-DDT00:00:00.000Z') });
                    }}/>
                </IonCol>
              </IonRow>
              <IonGrid>
                <IonRow>
                  <IonCol class="timeHeader">
                    <p>Fournisseur</p>
                  </IonCol>
                  <IonCol size="4" class="timeHeader">
                    <p>Montant</p>
                  </IonCol>
                </IonRow>
              </IonGrid>
              <IonRow>
                <IonCol class="timeColumn">
                  <IonInput
                    readonly={readonly}
                    name={'name'}
                    spellCheck={false}
                    type="text"
                    placeholder="Nom du fournisseur"
                    value={transfert.fournisseur}
                    onIonChange={(e) => setValue(i, { fournisseur: e.detail.value! })}
                    required
                  />
                </IonCol>
                <IonCol class="timeColumn" size="4">
                  <IonInput
                    readonly={readonly}
                    name={'montant'}
                    type="number"
                    value={String(transfert.montant || 0)}
                    placeholder="300.00"
                    onIonChange={(e) => setValue(i, { montant: Number(e.detail.value!) })}
                  />
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonItem>
        </IonItemSliding>)
        }
      </IonList> }
      { !readonly && transferts.length > 0 && <IonToolbar>
        <IonButtons slot="end">
          <IonButton slot="end" fill="solid" color="primary" onClick={() => onChange([
            ...value, {
              fournisseur: '',
              date: '',
              id_transfert: 0,
              montant: 0,
            }
          ])}>
            Ajouter
            <IonIcon icon={add}/>
          </IonButton>
        </IonButtons>
      </IonToolbar>}
    </>
  );
};

export default connect<TransfertListFormProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    transferts: getTransferts(state)
  }),
  mapDispatchToProps: {
    fetchTransferts
  },
  component: React.memo(TransfertListForm)
});
