import { ActionType } from '../../util/types';
import {getUserData, getUsers} from '../api/user';
import { Pagination } from '../../models/Pagination';
import { User } from '../../models/User';
import {getRoles} from "../api/role";
import {Role} from "../../models/Role";

export const setLoading = (isLoading: boolean) => ({
  type: 'set-users-loading',
  isLoading
} as const);

export const setUsers = (data: { users: User[]; pagination: Pagination; }) => ({
  type: 'set-users',
  data
} as const);

export const addUsers = (data: { users: User[]; pagination: Pagination; }) => ({
  type: 'add-users',
  data
} as const);

export const setRoles = (roles: Role[]) => ({
  type: 'set-roles',
  roles
} as const);

export const fetchUsers = (page?: number, truncate = false, idAgency?: number, idRole?: number, search?: string) => async (dispatch: React.Dispatch<any>) => {
  dispatch(setLoading(true));
  const data = await getUsers(page, idAgency, idRole, search);
  dispatch(truncate ? setUsers(data) : addUsers(data));
  dispatch(setLoading(false));
};

export const loadRoles = () => async (dispatch: React.Dispatch<any>) => {
  dispatch(setLoading(true));
  const data = await getRoles();
  dispatch(setRoles(data));
  dispatch(setLoading(false));
};

export type UsersActions =
  | ActionType<typeof setLoading>
  | ActionType<typeof setUsers>
  | ActionType<typeof addUsers>
  | ActionType<typeof setRoles>
