import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonModal, IonRadio,
  IonSearchbar,
  IonTitle,
  IonToolbar
} from '@ionic/react';
import React, { useEffect, useState } from 'react';
import { connect } from '../data/connect';
import { fetchAgencies } from '../data/agencies/agencies.actions';
import * as selectors from '../data/selectors';
import { Agency } from '../models/Agency';
import { Pagination } from '../models/Pagination';

interface AgencyInputProps {
  onSelect?: (id: number) => any;
}

interface StateProps {
  agencyList: { agencies: Agency[]; pagination: Pagination; };
}

interface DispatchProps {
  fetchAgencies: typeof fetchAgencies;
}

const AgencyInput: React.FC<AgencyInputProps & DispatchProps & StateProps> = ({ onSelect, agencyList, fetchAgencies }) => {

  const [disabled, setDisabled] = useState(false);
  const [isModalOpen, showModal] = useState(false);
  const [value, setValue] = useState({ id: 0, name: '' });
  useEffect(() => {
    fetchAgencies(1, true);
  }, []);

  const addAgencies = async (page: number) => {
    fetchAgencies(page);
  };
  return (
    <>
      <IonItem onClick={() => showModal(true)}>
        <IonLabel position="stacked" color="primary">Agence *</IonLabel>
        <IonInput value={value.name}/>
      </IonItem>
      <IonModal isOpen={isModalOpen} onDidDismiss={() => fetchAgencies(1, true)}>
        <IonHeader>
          <IonToolbar>
            <IonTitle>Agence</IonTitle>
            <IonButtons slot={'start'}>
              <IonButton onClick={() => showModal(false)}>Fermer</IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonSearchbar type="text"/>
        <IonContent>
          <IonList>
            {agencyList.agencies.map((agency: any, i: number) => <IonItem key={`agency-${i}`}>
              <IonLabel>{agency.name}</IonLabel>
              <IonRadio slot="start" checked={agency.id === value.id} onClick={() => {
                if (onSelect) {
                  onSelect(agency.id);
                }
                setValue(agency);
                showModal(false);
              }} />
            </IonItem>)}
          </IonList>
          <IonInfiniteScroll disabled={disabled} onIonInfinite={async (e: any) => {
            const currentPage = agencyList.pagination.currentPage;
            if (agencyList.pagination.totalPages === currentPage) {
              return setDisabled(true);
            }
            await addAgencies(currentPage + 1);
            e.target.complete();
          }}>
            <IonInfiniteScrollContent
              loadingSpinner="bubbles"
              loadingText="Chargement..."
            />
          </IonInfiniteScroll>
        </IonContent>
      </IonModal>
    </>
  );
};

export default connect<AgencyInputProps, {}, DispatchProps>({
  mapStateToProps: (state) => ({
    agencyList: selectors.getAgencies(state),
  }),
  mapDispatchToProps: {
    fetchAgencies
  },
  component: React.memo(AgencyInput)
});
