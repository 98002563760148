import React, { useState, useRef, useEffect } from 'react';
import {
  IonToolbar,
  IonContent,
  IonPage,
  IonButtons,
  IonMenuButton,
  IonRefresher,
  IonRefresherContent,
  IonToast,
  IonHeader,
  IonBackButton, IonTitle
} from '@ionic/react';
import { connect } from '../data/connect';
// import './ActivityPage.scss';
import ActivityList from '../components/ActivityList';
import { getActivities, getUserAgency } from '../data/selectors';
import { fetchActivities } from '../data/agency/agency.actions';
import { Activity } from '../models/Activity';
import { RouteComponentProps, withRouter } from 'react-router';
import { getAgency } from '../data/api/agency';

interface OwnProps { }

interface StateProps {
  activities: Activity[];
  agency: number;
  role: any;
}

interface DispatchProps {
  fetchActivities: typeof fetchActivities;
}

type ActivityPageProps = OwnProps & StateProps & DispatchProps & RouteComponentProps;

const ActivityPage: React.FC<ActivityPageProps> = ({ fetchActivities, activities, agency, location, role }) => {
  const ionRefresherRef = useRef<HTMLIonRefresherElement>(null);
  const [showCompleteToast, setShowCompleteToast] = useState(false);
  const [agencyName, setAgencyName] = useState('');
  const idAgency = Number(new URLSearchParams(location.search).get('agency')) || 0;

  useEffect(() => {
    if (idAgency) {
      getAgency(idAgency).then(({ agence }) => {
        setAgencyName(agence.name || '');
      });
    }
    fetchActivities(idAgency);
  }, []);

  const doRefresh = () => {
    fetchActivities(idAgency);
    setTimeout(() => {
      ionRefresherRef.current!.complete();
      setShowCompleteToast(true);
    }, 1000);
  };

  return (
    <IonPage id="pointing-page">
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            { role && role.id === 1 ?
              <IonBackButton defaultHref={`/tabs/agency/${idAgency}`}/> :
              <IonMenuButton />
            }
          </IonButtons>
          <IonTitle>
            Types d&apos;heures { agencyName ? `de ${agencyName}` : ''}
          </IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent>
        <IonRefresher slot="fixed" ref={ionRefresherRef} onIonRefresh={doRefresh}>
          <IonRefresherContent />
        </IonRefresher>
        <IonToast
          isOpen={showCompleteToast}
          message="Contenu rafraichi"
          duration={2000}
          onDidDismiss={() => setShowCompleteToast(false)}
        />
        <ActivityList idAgency={idAgency || agency} activityList={activities}/>
      </IonContent>
    </IonPage>
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    agency: getUserAgency(state),
    activities: getActivities(state),
    role: state.user.role
  }),
  mapDispatchToProps: {
    fetchActivities
  },
  component: withRouter(ActivityPage)
});
