/* eslint-disable */
/* eslint-disable import/first */
require('get-root-node-polyfill/implement');
const isImplemented = require('get-root-node-polyfill/is-implemented');
const _isImplement = isImplemented(); // true if the method is available

if (!_isImplement) {
  console.warn('GetRootNode Polyfill isn\'t implemented');
} else {
  console.log('GetRootNode Polyfill implemented');
}

import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

require('@webcomponents/custom-elements/custom-elements.min');

import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

ReactDOM.render(<App />, document.getElementById('root'));
