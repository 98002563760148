import React, { useState } from 'react';
import {
  IonToast,
  IonList,
  IonInput,
  IonItem,
  IonIcon,
  IonButton,
  IonListHeader,
  IonCard,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle, IonCardContent, IonLabel, IonCheckbox, IonSelectOption, IonSelect, IonCol, IonButtons, IonNote
} from '@ionic/react';
import { connect } from '../data/connect';
import { add, trash } from 'ionicons/icons';
import { Activity } from '../models/Activity';
import { deleteActivity, postActivity } from '../data/api/activity';
import { fetchActivities } from '../data/agency/agency.actions';
import { validateEmail } from '../util/validator';
import {act} from "react-dom/test-utils";
import TrashButton from "./TrashButton";

interface OwnProps {
  activityList: Activity[];
  idAgency: number;
}

interface DispatchProps {
  fetchActivities: typeof fetchActivities;
}

interface ActivityListProps extends OwnProps, DispatchProps {}

const ActivityList: React.FC<ActivityListProps> = ({ fetchActivities, activityList, idAgency }) => {
  const [showToast, setShowToast] = useState(false);
  const [toastText, setToastText] = useState('');
  const [activityInput, setActivityInput] = useState('');
  const [activityInputTotal, setActivityInputTotal] = useState(true);
  const [activityInputError, setActivityInputError] = useState(false);

  const addActivity = async (value: string) => {
    // Check if the email value isn't empty
    setActivityInputError(!value.length);
    setToastText(value.length ? `${value} ajouté` : 'Le champs est vide');
    if (!value.length) return setShowToast(true);

    const { data: result } = await postActivity(idAgency, value, activityInputTotal);
    if (!result.status) {
      const errorText = result.data.errors.email || 'Une erreur est survenue';
      setActivityInputError(true);
      setToastText(errorText);
      return setShowToast(true);
    }
    setToastText(result.data.alert.message);
    setActivityInput('');
    // Refresh activityList
    fetchActivities(idAgency);
    return setShowToast(true);
  };

  const removeActivity = async (idActivity: number) => {
    const { data: result } = await deleteActivity(idActivity);
    if (!result.status) {
      const errorText = 'Une erreur est survenue';
      setActivityInputError(true);
      setToastText(errorText);
      return setShowToast(true);
    }

    setToastText(result.data.alert.message);
    // Refresh activityList
    fetchActivities(idAgency);
    return setShowToast(true);
  };

  return (
    <>
      <IonCard>
        <IonCardHeader>
          <IonCardSubtitle>Ajouter un type d&apos;heure</IonCardSubtitle>
          <IonCardTitle>Type d&apos;heure</IonCardTitle>
        </IonCardHeader>
        <IonCardContent>
          <IonItem>
            <IonLabel position="stacked" color="primary">Nom</IonLabel>
            <IonInput
              placeholder={'En déplacement'}
              type={'text'}
              name={'activity'}
              value={activityInput}
              onIonChange={(e) => setActivityInput(e.detail.value!)}
              color={activityInputError ? 'danger' : ''}
              spellCheck={false}
              required/>
          </IonItem>
          <IonItem>
            <IonLabel position="stacked" color="primary">A comptabiliser dans le temps total</IonLabel>
            <IonSelect
              onIonChange={(e) => setActivityInputTotal(e.detail.value)}
              style={{ maxWidth: 'fit-content' }}
              placeholder="Zone de transfert"
              value={activityInputTotal}
              interface="popover"
              cancelText="Annuler"
            >
              <IonSelectOption value={true}>Oui</IonSelectOption>
              <IonSelectOption value={false}>Non</IonSelectOption>
            </IonSelect>
          </IonItem>
          <IonButtons class="ion-padding-top">
            <IonButton type="submit" expand="block" fill="solid" color="primary" onClick={() => addActivity(activityInput)}>
              Ajouter
            </IonButton>
          </IonButtons>
        </IonCardContent>
      </IonCard>
      <IonList>
        <IonListHeader>
          <p>{ activityList.length ? 'Liste des types d\'heures' : 'Aucun types d\'heures'}</p>
        </IonListHeader>
        { !!activityList.length &&
          activityList.map((activity) => <IonItem key={activity.id} style={{
            marginBottom: '5px'
          }}>
            <IonNote color={ activity.isIncludeInTotalHours ? 'primary' : 'danger' } slot="start">{activity.isIncludeInTotalHours ? 'Temps total' : 'Hors temps'}</IonNote>
            <IonLabel>
              {activity.name}
            </IonLabel>
            <TrashButton
              message={`Voulez-vous supprimer <strong>${activity.name}</strong> ?`}
              header={'Supprimer un type d\'heure'}
              onDelete={() => removeActivity(activity.id)}
            />
          </IonItem>)}
      </IonList>
      <IonToast
        isOpen={showToast}
        message={toastText}
        duration={2000}
        onDidDismiss={() => setShowToast(false)}
      />
    </>
  );
};

export default connect<OwnProps, {}, DispatchProps>({
  mapDispatchToProps: {
    fetchActivities
  },
  component: React.memo(ActivityList)
});
