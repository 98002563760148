import React, {useEffect, useState} from 'react';
import {
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonHeader, IonInput,
  IonItem, IonLabel,
  IonList,
  IonModal,
  IonRow,
  IonTitle,
  IonToolbar
} from '@ionic/react';

interface OwnProps {
  isOpen?: boolean;
  onClose?: () => any;
  name?: string;
  onSubmit?: (name: string) => any;
}

const AgencyExportModal: React.FC<OwnProps> = ({ isOpen = false, onClose, name: agencyName, onSubmit }) => {
  const [name, setName] = useState('');

  useEffect(() => {
    setName(agencyName || '');
  }, []);

  const close = () => {
    setName(agencyName || '');
    if (onClose) return onClose();
  };

  return (
    <IonModal isOpen={isOpen} onDidDismiss={() => close()}>
      <IonHeader translucent>
        <IonToolbar>
          <IonTitle>Éditer l&apos;agence</IonTitle>
          <IonButtons color={'danger'} slot="end" onClick={() => close()}>
            <IonButton>
              Fermer
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <form noValidate onSubmit={(e) => {
          e.preventDefault();
          if (onSubmit) return onSubmit(name);
        }}>
          <IonList>
            <IonItem>
              <IonLabel position="stacked" color="primary">Nom de l&apos;agence</IonLabel>
              <IonInput type="text" name={'carName'} spellCheck={false} value={name} onIonChange={(e) => setName(e.detail.value!)}/>
            </IonItem>
            <IonRow>
              <IonCol>
                <IonButton type="submit" expand="block">Enregistrer</IonButton>
              </IonCol>
            </IonRow>
          </IonList>
        </form>
      </IonContent>
    </IonModal>
  );
};

export default React.memo(AgencyExportModal);
