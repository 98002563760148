import React, { useState } from 'react';
import {
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonPage,
  IonButtons,
  IonMenuButton,
  IonRow,
  IonCol,
  IonButton,
  IonList,
  IonItem,
  IonLabel,
  IonInput,
  IonText,
  IonCard, IonGrid, IonToast, IonLoading
} from '@ionic/react';
import './Login.scss';
import { loadUserData, setIsLoggedIn, setUsername } from '../data/user/user.actions';
import { connect } from '../data/connect';
import { RouteComponentProps } from 'react-router';
import { postLogin } from '../data/api/user';

type OwnProps = RouteComponentProps

interface DispatchProps {
  setIsLoggedIn: typeof setIsLoggedIn;
  setUsername: typeof setUsername;
  loadUserData: typeof loadUserData;
}

interface LoginProps extends OwnProps,  DispatchProps { }

const Login: React.FC<LoginProps> = ({ setIsLoggedIn, history, setUsername: setUsernameAction, loadUserData }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [usernameError, setUsernameError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [showLoading, setShowLoading] = useState(false);

  const [showErrorToast, setShowErrorToast] = useState(false);

  const login = async (e: React.FormEvent) => {
    e.preventDefault();
    setShowLoading(true);
    setFormSubmitted(true);
    if (!username) {
      setUsernameError(true);
    }
    if (!password) {
      setPasswordError(true);
    }

    if (!username && !password) return;

    try {
      await postLogin(username, password);
      await setIsLoggedIn(true);
      await loadUserData();
      const firefox = window.navigator.userAgent.match(/Firefox\/([0-9]+)\./);
      if (firefox) {
        const { protocol, hostname, port } = window.location;
        return window.location.href = `${protocol}//${hostname}${port ? `:${port}` : ''}/tabs/agency`;
      }
      setShowLoading(false);
      history.push('/', { direction: 'none' });
    } catch (e) {
      setShowLoading(false);
      return setShowErrorToast(true);
    }
  };

  return (
    <IonPage id="login-page">
      <IonHeader>
        <IonToolbar>
          <IonTitle>Connexion</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonGrid>
          <IonRow>
            <IonCol>
              <IonCard>
                <form noValidate onSubmit={login}>
                  <IonList>
                    <IonItem>
                      <IonLabel position="stacked" color="primary">Adresse email *</IonLabel>
                      <IonInput name="adresse email" placeholder="insérer votre email" type="email" value={username} spellCheck={false} autocapitalize="off" onIonChange={e => setUsername(e.detail.value!)}
                        required>
                      </IonInput>
                    </IonItem>

                    {formSubmitted && usernameError && <IonText color="danger">
                      <p className="ion-padding-start">
                            L&apos;adresse email est requise
                      </p>
                    </IonText>}

                    <IonItem>
                      <IonLabel position="stacked" color="primary">Mot de passe *</IonLabel>
                      <IonInput name="mot de passe" placeholder="insérer votre mot de passe" type="password" value={password} onIonChange={e => setPassword(e.detail.value!)}>
                      </IonInput>
                    </IonItem>

                    {formSubmitted && passwordError && <IonText color="danger">
                      <p className="ion-padding-start">
                            Le mot de passe est requise
                      </p>
                    </IonText>}
                  </IonList>

                  <IonRow>
                    <IonCol>
                      <IonButton type="submit" expand="block">Se connecter</IonButton>
                    </IonCol>
                  </IonRow>
                </form>
              </IonCard>
            </IonCol>
          </IonRow>
          <IonToast
            isOpen={showErrorToast}
            message="Erreur d'authentification"
            duration={2000}
            onDidDismiss={() => setShowErrorToast(false)}
          />
        </IonGrid>
      </IonContent>
      <IonLoading
        isOpen={showLoading}
        onDidDismiss={() => setShowLoading(false)}
        message={'Connexion en cours...'}
        duration={1000}
      />
    </IonPage>
  );
};

export default connect<OwnProps, {}, DispatchProps>({
  mapDispatchToProps: {
    setIsLoggedIn,
    setUsername,
    loadUserData
  },
  component: Login
});
