import {
  IonButton,
  IonButtons,
  IonCol,
  IonDatetime, IonGrid, IonIcon,
  IonItem, IonItemOption, IonItemOptions, IonItemSliding, IonLabel,
  IonList, IonRow, IonSelect, IonSelectOption, IonToolbar
} from '@ionic/react';
import React, { useEffect, useState } from 'react';
import { connect } from '../data/connect';
import { Activity as AgencyActivity } from '../models/Activity';

import moment from 'moment';
import './ScheduleForm.scss';
import { add, trash } from 'ionicons/icons';
import { getActivities } from '../data/selectors';
import { fetchActivities } from '../data/agency/agency.actions';
import { TimeFormat } from '../util/convert';

interface OwnProps {
  value: Activity[];
  onChange: (form: Activity[]) => any;
  readonly?: boolean;
}

interface StateProps {
  activities: AgencyActivity[];
}

interface DispatchProps {
  fetchActivities: typeof fetchActivities;
}

interface Activity {
  idActivity: number;
  isIncludeInTotalHours: boolean;
  startDate: string;
  endDate: string;
}

const ScheduleForm: React.FC<OwnProps & DispatchProps & StateProps> = ({ activities, value, onChange, readonly }) => {

  const form = value || [];
  const setForm = (data: any) => onChange(data);

  const interfaceOpts = {
    header: 'Type d\'heure',
    subHeader: activities.length ? 'Sélectionner votre type d\'heure' : 'Aucun type d\'heure',
  };

  const setValue = (key: number, data: Partial<Activity>) => {
    const newForm = form.map((a, i) => ({
      ...a,
      ...i === key ? data : {}
    }));
    setForm(newForm);
  };

  const addActivity = (data = { startDate: form[form.length - 1].endDate, endDate: '', idActivity: 0 } as Activity) => {
    setForm([...form, data]);
  };

  const removeActivity = (key: number) => {
    setForm([...form.filter((_, i) => i !== key)]);
  };

  const generateTimeValues = (min: Date) => {
    const closest = (goal: number) => [0, 15, 30, 45].reduce(function(prev, curr) {
      return Math.abs(curr - goal) < Math.abs(prev - goal) ? curr : prev;
    });
    const minMinute = min.getMinutes() >= 45 ? 0 : closest(min.getMinutes());
    const minHour = min.getMinutes() >= 45 ? min.getHours() + 1 : min.getHours();

    return {
      hourValues: Array.from(Array(24), (_, x) => x).slice(minHour),
      minuteValues: [0, 15, 30, 45].filter((a) => a >= minMinute)
    };
  };

  const totalTime = TimeFormat(form
    .filter((data) => {
      const activity = activities.find((a) => a.id === data.idActivity);
      if (!activity) return false;
      return activity.isIncludeInTotalHours;
    })
    .reduce((acc: number, activity: Activity): any => {
      const dateStart = new Date(activity.startDate).getTime();
      const dateEnd = new Date(activity.endDate).getTime();
      if (!dateStart || !dateEnd) return acc;
      return acc + Math.round((dateEnd - dateStart) / 1000);
    }, 0) || 0);

  if (!activities.length) {
    return <IonList><p className="ion-padding-start">Aucuns types d&apos;heures pour cette agence</p></IonList>;
  }

  return (
    <IonList>
      <IonItem id="scheduleHeader">
        <IonGrid>
          <IonRow>
            <IonCol size="6" class="timeHeader">
              <p>Type d&apos;heure</p>
            </IonCol>
            <IonCol size="3" class="timeHeader">
              <p>Début</p>
            </IonCol>
            <IonCol size="3" class="timeHeader">
              <p>Fin</p>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonItem>
      {form.map((activity, i) => <IonItemSliding key={`activity-${activity.idActivity}-${i}`}>
        { i !== 0 && <IonItemOptions side="end">
          <IonItemOption icon-only color={'danger'} onClick={() => removeActivity(i)}>
            <IonIcon slot="icon-only" icon={trash} />
          </IonItemOption>
        </IonItemOptions>}
        <IonItem>
          <IonGrid>
            <IonRow>
              <IonCol class="timeColumn" size="6">
                { !readonly && <IonSelect
                  onIonChange={(e) => setValue(i, { idActivity: e.detail.value })}
                  style={{ maxWidth: 'fit-content' }}
                  placeholder="Type d'heure"
                  value={activity.idActivity}
                  interfaceOptions={interfaceOpts}
                  cancelText="Annuler"
                >
                  {activities.map(activity => <IonSelectOption key={`activity-${activity.id}`} value={activity.id}>
                    {activity.name} {!activity.isIncludeInTotalHours ? '(hors temps)' : ''}
                  </IonSelectOption>)}
                </IonSelect>
                }
                {
                  readonly &&
                  <IonItem>
                    <IonLabel class="ion-text-center">
                      {(activities.find(a => a.id === activity.idActivity) || { name: '' }).name}
                    </IonLabel>
                  </IonItem>
                }
              </IonCol>
              <IonCol class="timeColumn" size="3">
                <IonDatetime
                  cancelText="annuler"
                  doneText="confirmer"
                  minuteValues={[0, 15, 30, 45]}
                  {...i >= 1 ? { hourValues: generateTimeValues(new Date(form[i - 1].endDate)).hourValues } : {}}
                  readonly={readonly}
                  style={{ paddingLeft: 5 }}
                  placeholder={'07:00'}
                  displayFormat="HH:mm"
                  pickerFormat="HH:mm"
                  value={activity.startDate} onIonChange={(e) => {
                    const value = e.detail.value!;
                    if (!value) return;
                    console.log(moment(value).format('YYYY-MM-DDTHH:mm:00.000Z'));
                    setValue(i, { startDate: moment(value).format('YYYY-MM-DDTHH:mm:00.000Z') });
                    if (activity.endDate && activity.endDate.length) return;
                    const { hourValues, minuteValues } = generateTimeValues(new Date(value));
                    const endDate = new Date(value);
                    endDate.setHours(hourValues[0]);
                    endDate.setMinutes(minuteValues[0] + 15);
                    setValue(i, { endDate: moment(endDate).format('YYYY-MM-DDTHH:mm:00.000Z') });
                  }}/>
              </IonCol>
              <IonCol class="timeColumn" size="3">
                <IonDatetime
                  cancelText="annuler"
                  doneText="confirmer"
                  readonly={readonly}
                  style={{ paddingLeft: 5 }}
                  placeholder={'09:00'}
                  minuteValues={[0, 15, 30, 45]}
                  {...activity.startDate ? { hourValues: generateTimeValues(new Date(activity.startDate)).hourValues } : {}}
                  displayFormat="HH:mm"
                  pickerFormat="HH:mm"
                  value={activity.endDate}
                  onIonChange={(e) => {
                    const value  = e.detail.value;
                    if (!value) return;
                    setValue(i, { endDate: moment(value).format('YYYY-MM-DDTHH:mm:00.000Z') });
                  }}
                />
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonItem>
      </IonItemSliding>)}
      <IonToolbar>
        { !readonly &&
          <IonButtons slot="end">
            <IonButton slot="end" fill="solid" color="primary" onClick={() => addActivity()}>
                  Ajouter
              <IonIcon icon={add}/>
            </IonButton>
          </IonButtons>
        }
        <div>
          <p hidden={!totalTime.minutes && !totalTime.hours}>
            <b>Total:</b>
                &nbsp;
            { totalTime.hours } {`heure${totalTime.hours > 1 ? 's' : ''}`}
                &nbsp;
            { totalTime.minutes > 0 ? `et ${totalTime.minutes}` : ''}
                &nbsp;
            {`${totalTime.minutes > 0 ? 'minute' : ''}${totalTime.minutes > 1 ? 's' : ''}`}
          </p>
        </div>
      </IonToolbar>
    </IonList>
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    activities: getActivities(state)
  }),
  mapDispatchToProps: {
    fetchActivities
  },
  component: React.memo(ScheduleForm)
});
