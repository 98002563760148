import {
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonMenu,
  IonMenuToggle,
  IonTitle,
  IonToolbar,
  IonToggle
} from '@ionic/react';
import {
  business,
  calendar,
  logIn,
  logOut,
  notifications,
  people,
  person,
  swap,
  personAdd, timer, wallet
} from 'ionicons/icons';

import React, { useState } from 'react';
import { connect } from '../data/connect';
import { RouteComponentProps, withRouter } from 'react-router';
import { setDarkMode } from '../data/user/user.actions';
import { version as packageVersion } from '../../package.json';

const routes = {
  appPages: [{ title: 'Pointage', path: '/tabs/pointing', icon: calendar }],
  loggedInPages: [
    { title: 'Mon compte', path: '/account', icon: person },
    { title: 'Déconnexion', path: '/logout', icon: logOut }
  ],
  superAdminPages: [
    { title: 'Agences', path: '/tabs/agency', icon: business },
    { title: 'Utilisateurs', path: '/tabs/user', icon: people }
  ],
  adminPages: [
    { title: 'Utilisateurs', path: '/tabs/user', icon: people },
    { title: 'Notifications', path: '/tabs/notification', icon: notifications },
    { title: 'Primes', path: '/tabs/bounty', icon: wallet },
    { title: 'Type d\'heure', path: '/tabs/activity', icon: timer },
    { title: 'Zone de transferts', path: '/tabs/transfert', icon: swap },
  ],
  loggedOutPages: [
    { title: 'Login', path: '/login', icon: logIn },
    { title: 'Signup', path: '/signup', icon: personAdd }
  ]
};

interface Pages {
  title: string;
  path: string;
  icon: { ios: string; md: string; };
  routerDirection?: string;
}
interface StateProps {
  darkMode: boolean;
  isAuthenticated: boolean;
  role: any;
  agency: number;
}

interface DispatchProps {
  setDarkMode: typeof setDarkMode;
}

interface MenuProps extends RouteComponentProps, StateProps, DispatchProps { }

const Menu: React.FC<MenuProps> = ({ darkMode, history, isAuthenticated, role, agency, setDarkMode }) => {
  const [disableMenu, setDisableMenu] = useState(false);

  function renderlistItems(list: Pages[]) {
    return list
      .filter(route => !!route.path)
      .map(p => <IonMenuToggle key={p.title} auto-hide="false">
        <IonItem button routerLink={p.path} routerDirection="none">
          <IonIcon color="primary" slot="start" icon={p.icon} />
          <IonLabel>{p.title}</IonLabel>
        </IonItem>
      </IonMenuToggle>);
  }

  const isSuperAdmin = isAuthenticated && role && role.name === 'Super Administrateur';
  const isAdmin = isAuthenticated && role && role.name === 'Administrateur';

  return (
    <IonMenu swipeGesture type="overlay" disabled={disableMenu} contentId="main">
      <IonHeader>
        <IonToolbar>
          <IonTitle>Menu</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent class="outer-content">
        {!isSuperAdmin && <IonList>
          <IonListHeader>Navigation</IonListHeader>
          {renderlistItems(routes.appPages)}
        </IonList>}
        {isSuperAdmin &&
          <IonList>
            <IonListHeader>Administration</IonListHeader>
            {renderlistItems(routes.superAdminPages)}
          </IonList>
        }
        {
          isAdmin &&
          <IonList>
            <IonListHeader>Administration</IonListHeader>
            {renderlistItems(routes.adminPages)}
            <IonMenuToggle auto-hide="false">
              <IonItem button routerLink={`/tabs/agency/${agency}`} routerDirection="none">
                <IonIcon color="primary" slot="start" icon={business} />
                <IonLabel>Mon agence</IonLabel>
              </IonItem>
            </IonMenuToggle>
          </IonList>
        }
        <IonList>
          <IonListHeader>Compte</IonListHeader>
          {isAuthenticated ? renderlistItems(routes.loggedInPages) : renderlistItems(routes.loggedOutPages)}
        </IonList>
        <IonList>
          <IonItem>
            <IonLabel>Thème sombre</IonLabel>
            <IonToggle checked={darkMode} onClick={() => setDarkMode(!darkMode)} />
          </IonItem>
        </IonList>
        <IonLabel style={{ paddingLeft: 16, fontSize: 14 }}>Version {packageVersion}</IonLabel>
      </IonContent>
    </IonMenu>
  );
};

export default connect<{}, StateProps, {}>({
  mapStateToProps: (state) => ({
    darkMode: state.user.darkMode,
    isAuthenticated: state.user.isLoggedin,
    role: state.user.role,
    agency: state.user.agency
  }),
  mapDispatchToProps: {
    setDarkMode
  },
  component: withRouter(Menu)
});
