import { UsersActions } from './users.actions';
import { UsersState } from './users.state';

export function usersReducer(state: UsersState, action: UsersActions): UsersState {
  switch (action.type) {
    case 'set-users-loading':
      return { ...state, loading: action.isLoading };
    case 'set-users':
      return { ...state, data: action.data } as any;
    case 'set-roles':
      return { ...state, roles: action.roles } as any;
    case 'add-users':
      return {
        ...state,
        data: {
          users: [...state.data.users, ...action.data.users],
          pagination: action.data.pagination
        }
      };
  }
}
