import Storage, { store } from '../index';
import { HAS_LOGGED_IN, HAS_SEEN_TUTORIAL, IS_STORED, TOKEN, USER_AGENCY, USER_INFO, USER_ROLE, USERNAME, DARK_MODE } from './keys';

export const setIsLoggedInData = async (isLoggedIn: boolean) => {
  await Storage.set({ key: HAS_LOGGED_IN, value: JSON.stringify(isLoggedIn) });
};

export const setHasSeenTutorialData = async (hasSeenTutorial: boolean) => {
  await Storage.set({ key: HAS_SEEN_TUTORIAL, value: JSON.stringify(hasSeenTutorial) });
};

export const setUsernameData = async (username?: string) => {
  if (!username) {
    await Storage.remove({ key: USERNAME });
  } else {
    await Storage.set({ key: USERNAME, value: username });
  }
};

export const setIsStored = async (isStored?: boolean) => {
  await Storage.set({ key: IS_STORED, value: JSON.stringify(isStored) });
};


export const setDarkModeData = async (darkMode: boolean) => {
  await Storage.set({ key: DARK_MODE, value: JSON.stringify(darkMode) });
};

export const clearUser = () => {
  return Promise.all([
    Storage.remove({ key: USER_INFO }),
    Storage.remove({ key: USER_ROLE }),
    Storage.remove({ key: USER_AGENCY }),
    Storage.remove({ key: TOKEN }),
    Storage.remove({ key: HAS_LOGGED_IN }),
    Storage.remove({ key: IS_STORED })
  ]);
};

export const getUser = () => Promise.all([
  Storage.get({ key: HAS_LOGGED_IN }),
  Storage.get({ key: HAS_SEEN_TUTORIAL }),
  Storage.get({ key: TOKEN }),
  Storage.get({ key: USER_ROLE }),
  Storage.get({ key: USER_AGENCY }),
  Storage.get({ key: IS_STORED }),
  Storage.get({ key: USER_INFO }),
  Storage.get({ key: DARK_MODE })
]);

export const saveUser = (data: any) => store(data);
