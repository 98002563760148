import React, { useRef, useState } from 'react';
import {
  IonItemSliding,
  IonAlert,
  IonItem,
  IonLabel,
  IonItemOptions,
  IonItemOption,
  IonIcon
} from '@ionic/react';
import { Time } from './Time';
import { Agency } from '../models/Agency';
import { trash } from 'ionicons/icons';

interface AgencyListItemProps {
  agency: Agency;
  onRemove?: (id: number) => any;
}

const AgencyListItem: React.FC<AgencyListItemProps> = ({ agency, onRemove }) => {
  const ionItemSlidingRef = useRef<HTMLIonItemSlidingElement>(null);
  const [showAlert, setShowAlert] = useState(false);

  return (
    <IonItemSliding ref={ionItemSlidingRef} class={`agency-${agency.id}`}>
      <IonItem routerLink={`/tabs/agency/${agency.id}`}>
        <IonLabel>
          <h3><b>{agency.name}</b> #{agency.id}</h3>
          <p>
            <Time date={agency.createdAt} />
          </p>
        </IonLabel>
      </IonItem>
      <IonItemOptions slot="end">
        <IonItemOption icon-only color="danger" onClick={() => setShowAlert(true)}>
          <IonIcon slot="icon-only" icon={trash}/>
        </IonItemOption>
      </IonItemOptions>
      <IonAlert
        isOpen={showAlert}
        onDidDismiss={() => setShowAlert(false)}
        header={'Supprimer une agence'}
        message={`Voulez-vous supprimer <strong>${agency.name}</strong> ?`}
        buttons={[
          'Annuler', {
            text: 'Supprimer',
            handler: () => onRemove && onRemove(agency.id)
          }
        ]}
      />
    </IonItemSliding>
  );
};

export default React.memo(AgencyListItem);
