import React, { useState } from 'react';
import {
  IonToast,
  IonList,
  IonInput,
  IonItem,
  IonIcon,
  IonButton,
  IonListHeader,
  IonCard,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle, IonCardContent, IonLabel
} from '@ionic/react';
import { connect } from '../data/connect';
import { add, trash } from 'ionicons/icons';
import { Transfert } from '../models/Transfert';
import { deleteTransfert, postTransfert } from '../data/api/transfert';
import { fetchTransferts } from '../data/agency/agency.actions';
import TrashButton from "./TrashButton";

interface OwnProps {
  transfertList: Transfert[];
  idAgency: number;
}

interface DispatchProps {
  fetchTransferts: typeof fetchTransferts;
}

interface TransfertListProps extends OwnProps, DispatchProps {}

const TransfertList: React.FC<TransfertListProps> = ({ fetchTransferts, transfertList, idAgency }) => {
  const [showToast, setShowToast] = useState(false);
  const [toastText, setToastText] = useState('');
  const [transfertInput, setTransfertInput] = useState('');
  const [transfertInputError, setTransfertInputError] = useState(false);

  const addTransfert = async (value: string) => {
    // Check if the email value isn't empty
    setTransfertInputError(!value.length);
    setToastText(value.length ? `${value} ajouté` : 'Le champs est vide');
    if (!value.length) return setShowToast(true);

    const { data: result } = await postTransfert(idAgency, value);
    if (!result.status) {
      console.log(result);
      const errorText = result.data.errors || 'Une erreur est survenue';
      setTransfertInputError(true);
      setToastText(errorText);
      return setShowToast(true);
    }
    setToastText(result.data.alert.message);
    setTransfertInput('');
    // Refresh transfertList
    fetchTransferts(idAgency);
    return setShowToast(true);
  };

  const removeTransfert = async (idTransfert: number) => {
    const { data: result } = await deleteTransfert(idTransfert);
    if (!result.status) {
      const errorText = 'Une erreur est survenue';
      setTransfertInputError(true);
      setToastText(errorText);
      return setShowToast(true);
    }

    setToastText(result.data.alert.message);
    // Refresh transfertList
    fetchTransferts(idAgency);
    return setShowToast(true);
  };

  return (
    <>
      <IonCard>
        <IonCardHeader>
          <IonCardSubtitle>Ajouter une zone de transfert</IonCardSubtitle>
          <IonCardTitle>Zone de transfert</IonCardTitle>
        </IonCardHeader>
        <IonCardContent>
          <IonItem>
            <IonInput
              placeholder={'Zone 1'}
              type={'text'}
              name={'transfert'}
              value={transfertInput}
              onIonChange={(e) => setTransfertInput(e.detail.value!)}
              color={transfertInputError ? 'danger' : ''}
              spellCheck={false}
              required/>
            <IonButton icon-only fill="clear" onClick={() => addTransfert(transfertInput)}>
              <IonIcon slot="icon-only" icon={add} color={'primary'}/>
            </IonButton>
          </IonItem>
        </IonCardContent>
      </IonCard>
      <IonList>
        <IonListHeader>
          <p>{ transfertList.length ? 'Liste de zones' : 'Aucune zone de transfert'}</p>
        </IonListHeader>
        { !!transfertList.length &&
          transfertList.map((transfert) => <IonItem key={transfert.id} style={{
            marginBottom: '5px'
          }}>
            <IonLabel>
              {transfert.name}
            </IonLabel>
            <TrashButton
              message={`Voulez-vous supprimer <strong>${transfert.name}</strong> ?`}
              header={'Supprimer une zone de transfert'}
              onDelete={() => removeTransfert(transfert.id || 0)}
            />
          </IonItem>)}
      </IonList>
      <IonToast
        isOpen={showToast}
        message={toastText}
        duration={2000}
        onDidDismiss={() => setShowToast(false)}
      />
    </>
  );
};

export default connect<OwnProps, {}, DispatchProps>({
  mapDispatchToProps: {
    fetchTransferts
  },
  component: React.memo(TransfertList)
});
