import {
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonItemDivider,
  IonItemGroup,
  IonList,
  IonListHeader
} from '@ionic/react';
import React, { useState } from 'react';
// import PointingListItem from './PointingListItem';
import { connect } from '../data/connect';
import { PointingState } from '../util/types';
import { getPointings, getPointingState } from '../data/selectors';
import { fetchPointings } from '../data/pointings/pointings.actions';
import { Pointing } from '../models/Pointing';
import { Pagination } from '../models/Pagination';
import PointingListItem from './PointingListItem';

interface OwnProps {
  disable?: (disable: any) => any;
  idAgency: number;
  onCopy?: (id: string | number) => any;
  onShare?: (id: string | number) => any;
}

interface StateProps {
  pointingList: { pointings: Pointing[]; pagination: Pagination; };
  pointingState: PointingState;
}

interface DispatchProps {
  fetchPointings: typeof fetchPointings;
}

interface PointingListProps extends OwnProps, StateProps, DispatchProps { }


const STATE = [PointingState.all, PointingState.pending, PointingState.validated, PointingState.refused];

const PointingList: React.FC<PointingListProps> = ({ pointingList, disable, idAgency, onCopy, pointingState, fetchPointings, onShare }) => {

  const [disabled, setDisabled] = useState(false);
  if (disable) {
    disable(setDisabled);
  }

  if (pointingList.pointings.length === 0) {
    return (
      <IonList>
        <IonListHeader>
          Aucun pointages trouvés
        </IonListHeader>
      </IonList>
    );
  }

  return (
    <>
      <IonList>
        {pointingList.pointings.length && pointingList.pointings.map((pointing, index: number) => <IonItemGroup key={`pointings-${index}`}>
          <IonItemDivider sticky>
            <PointingListItem
              idAgency={idAgency}
              onCopy={(id) => onCopy && onCopy(id)} value={pointing} state={STATE[Number(pointing.state)]}
              onShare={(id) => onShare && onShare(id)}
            />
          </IonItemDivider>
        </IonItemGroup>)}
      </IonList>
      <IonInfiniteScroll disabled={disabled} onIonInfinite={(e) => {
        const currentPage = pointingList.pagination.currentPage;
        if (pointingList.pagination.totalPages === currentPage) {
          return setDisabled(true);
        }
        fetchPointings(currentPage + 1, false, idAgency, pointingState);
        setTimeout(() => {
          // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
          // @ts-ignore
          e.target.complete();
        }, 500);
      }}>
        <IonInfiniteScrollContent
          loadingSpinner="bubbles"
          loadingText="Chargement..."
        />
      </IonInfiniteScroll>
    </>
  );
};

export default connect({
  mapStateToProps: (state) => ({
    pointingList: getPointings(state),
    pointingState: getPointingState(state)
  }),
  mapDispatchToProps: {
    fetchPointings,
  },
  component: PointingList
});
