import React, { useEffect, useState } from 'react';
import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonHeader,
  IonLoading,
  IonPage,
  IonRow,
  IonToast,
  IonToolbar
} from '@ionic/react';
import { connect } from '../data/connect';
import { RouteComponentProps, withRouter } from 'react-router';
import { getPointing, postSignature } from '../data/api/pointing';
import PointingForm from '../components/PointingForm';
import { getCurrentUser, getPointingState } from '../data/selectors';
import SignatureModal from '../components/SignatureModal';
import { PointingState } from '../util/types';
import {fetchActivities, fetchTransferts} from '../data/agency/agency.actions';
import { fetchPointings } from '../data/pointings/pointings.actions';

type OwnProps = RouteComponentProps

interface StateProps {
  user: any;
  pointingState: PointingState;
}

interface DispatchProps {
  fetchActivities: typeof fetchActivities;
  fetchPointings: typeof fetchPointings;
  fetchTransferts: typeof fetchTransferts;
}

type SessionDetailProps = OwnProps & StateProps & DispatchProps;

const PointingDetails: React.FC<SessionDetailProps> = ({ match, history, location, user, fetchActivities, fetchTransferts, fetchPointings, pointingState }) => {

  const [showLoading, setShowLoading] = useState(false);
  const [showError, setShowError] = useState(false);
  const [state, setState] = useState(0);
  const [message, setMessage] = useState('');

  const [edit, setEdit] = useState(false);
  const [editForm, setEditForm] = useState({});
  const [pointingSubmitted, setPointingSubmitted] = useState(false);
  const token = new URLSearchParams(location.search).get('token') || '';
  const [idAgency, setIdAgency] = useState(0);
  // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
  // @ts-ignore
  const id = Number(match.params.id);

  let setPointingForm: any = null;
  let pointingForm: any = null;

  useEffect(() => {
    if (isNaN(id)) {
      return setShowLoading(false);
    }
    getPointing(id).then(async (pointing) => {
      if (!pointing) return setShowLoading(false);
      const pointingDetails = {
        token: pointing.token,
        date: pointing.date,
        constructionSitename: pointing.constructionSitename,
        constructionSiteNumber: pointing.constructionSiteNumber,
        activity: pointing.activity.map((activity: any) => ({
          idActivity: Number(activity.id_activity),
          startDate: activity.startDate,
          endDate: activity.endDate
        })),
        bounty: pointing.bountyValue.map((bounty: any) => ({
          id: Number(bounty.id),
          name: bounty.name,
          idBountyValue: Number(bounty.id_bounty_value),
          bountyValues: bounty.bountyValues.map((b: any) => ({ isDefault: b.isDefault === '1', id: b.id, value: b.value }))
        })),
        transfert: pointing.transfert,
        fuelQuantity: pointing.fuelQuantity,
        fuelProvider: pointing.fuelProvider,
        vehicleName: pointing.vehicleName,
        vehicleNumber: pointing.vehicleNumber,
        validatorName: pointing.validatorName,
        machineName: pointing.machineName,
        machineNumber: pointing.machineNumber,
        comment: pointing.comment,
        validatorEmail: pointing.validatorEmail
      };
      setState(Number(pointing.state));
      setIdAgency(pointing.agency);
      setPointingForm(pointingDetails);
      fetchActivities(pointing.agency, true);
      fetchTransferts(pointing.agency, true);
    });
  }, []);

  const isSignable = () => {
    if (!edit && user && user.role && user.role.id !== 3) {
      return true;
    }
    return !!token;
  };

  const signPointing = async (token: string, signature: string, state: PointingState) => {
    const result = await postSignature(token, signature, state);
    if (!result) {
      setMessage('Une erreur est survenue');
      return setShowError(true);
    }
    setMessage(state === PointingState.refused ? 'Pointage refusé' : 'Pointage validé');
    fetchPointings(1, true, idAgency, pointingState);
    return setShowError(true);
  };

  const didUpdate = (result: any, willBeSigned: boolean) => {
    if (result.status && (result.status !== true || typeof result.status === 'number' && result.status !== 200)) {
      setShowLoading(false);
      setMessage('Une erreur est survenue');
      return setShowError(true);
    }
    setMessage(result.data.alert.message);
    setShowError(true);
    setShowLoading(false);
    setEdit(false);
    if (willBeSigned) return setPointingSubmitted(true);
  };

  return (
    <IonPage id="pointing-detail-page">
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref={ idAgency ? `/tabs/pointing?agency=${idAgency}` : '/tabs/pointing'}/>
          </IonButtons>
          { user && user.role && user.role.id !== 3 &&
            <IonButtons slot="end">
              { !edit ?
                <IonButton type="submit" expand="block" color="default" onClick={() => {
                  setEditForm({ ...pointingForm });
                  setEdit(true);
                }}>
                  Editer
                </IonButton> :
                <IonButton type="submit" expand="block" color="danger" onClick={() => {
                  setEdit(false);
                  setPointingForm({ ...editForm });
                }}>
                  Annuler
                </IonButton>
              }
            </IonButtons>
          }
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <PointingForm onUpdate={(result, toSigned) => didUpdate(result, toSigned)} readOnly={!edit} update={edit ? id : 0} actionsDisabled={true} formRef={({ setForm, form }) => { setPointingForm = setForm; pointingForm = form; } }/>
        { state === 1 && isSignable() &&
            <IonRow>
              <IonCol>
                <IonButton type="submit" expand="block" color="danger" onClick={async () => {
                  setShowLoading(true);
                  await signPointing(token || pointingForm.token, '', PointingState.refused);
                  setShowLoading(false);
                  return history.push(`/tabs/pointing${idAgency ? `?agency=${idAgency}` : ''}`, { direction: 'none' });
                }}>Refuser</IonButton>
              </IonCol>
              <IonCol>
                <IonButton type="submit" expand="block" onClick={() => setPointingSubmitted(true)}>Signer</IonButton>
              </IonCol>
            </IonRow>
        }
      </IonContent>
      <SignatureModal isOpen={pointingSubmitted}
        onClose={() => setPointingSubmitted(false)}
        onSave={async (signature) => {
          setShowLoading(true);
          await signPointing(token || pointingForm.token, signature, PointingState.validated);
          setShowLoading(false);
          history.push(`/tabs/pointing${idAgency ? `?agency=${idAgency}` : ''}`, { direction: 'none' });
          return setPointingSubmitted(false);
        }}/>
      <IonToast
        isOpen={showError}
        message={message}
        duration={2000}
        onDidDismiss={() => setShowError(false)}
      />
      <IonLoading
        isOpen={showLoading}
        onDidDismiss={() => setShowLoading(false)}
        message={'Chargement...'}
      />
    </IonPage>
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    user: getCurrentUser(state),
    pointingState: getPointingState(state)
  }),
  mapDispatchToProps: {
    fetchActivities,
    fetchPointings,
    fetchTransferts
  },
  component: withRouter(PointingDetails)
});
