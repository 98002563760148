import axios from '../axios';
import {PointingState} from "../../../util/types";

export const postPointing = async (data: any) => {
  try {
    return await axios.post(`/pointing/register`, data);
  } catch (e) {
    return e.response;
  }
};

export const updatePointing = async (id: number, data: any) => {
  try {
    return await axios.put(`/pointing/update/${id}`, data);
  } catch (e) {
    return e.response;
  }
};

export const deletePointing = async (idPointing: number) => {
  try {
    return await axios.delete(`/agence/removePointing/${idPointing}`);
  } catch (e) {
    return e.response;
  }
};

export const getPointings = async (page?: number, agency?: number, state?: PointingState) => {
  const { data: result } = await axios.get(`/pointing/retrievePointingWithFilter/${agency}`, {
    params: {
      page,
      limit: 15,
      state
    }
  });
  return result.data;
};

export const getPointing = async (id: number) => {
  try {
    const { data: result } = await axios.get(`/pointing/retrieve/${id}`);
    return result.data.pointing;
  } catch (e) {
    return e.response;
  }
};

export const postSignature = async (token: string, signature: string, state: PointingState) => {
  try {
    return await axios.post(`/pointing/signature/${token}`, {
      signature,
      state
    });
  } catch (e) {
    return e.response;
  }
};

export const postSend = async (id: number, email: string) => {
  try {
    return await axios.post(`/pointing/sendByEmail/${id}`, {
      email
    });
  } catch (e) {
    return e.response;
  }
};

export const postValidation = async (id: number, email: string) => {
  try {
    return await axios.post(`/pointing/sendByEmail/${id}`, {
      email
    });
  } catch (e) {
    return e.response;
  }
};
