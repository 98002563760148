import { combineReducers } from './combineReducers';
import { userReducer } from './user/user.reducer';
import { agenciesReducer } from './agencies/agencies.reducer';
import { usersReducer } from './users/users.reducer';
import { agencyReducer } from './agency/agency.reducer';
import {pointingsReducer} from "./pointings/pointings.reducer";
import {PointingState} from "../util/types";

export const initialState: AppState = {
  agency: {
    id: 0,
    name: '',
    createdAt: '',
    loading: false,
    notifications: [],
    activities: [],
    bounties: [],
    transferts: []
  },
  agencies: {
    data: {
      agencies: [],
      pagination: {
        totalPages: 0,
        totalRow: 0,
        currentPage: 0
      },
    },
    loading: false
  },
  users: {
    roles: [],
    data: {
      users: [],
      pagination: {
        totalPages: 0,
        totalRow: 0,
        currentPage: 0
      }
    },
    loading: false
  },
  pointings: {
    state: PointingState.all,
    data: {
      pointings: [],
      pagination: {
        totalPages: 0,
        totalRow: 0,
        currentPage: 0
      },
    },
    loading: false
  },
  user: {
    hasSeenTutorial: false,
    darkMode: false,
    isLoggedin: false,
    loading: false,
    agency: 0,
    role: undefined,
    infos: null
  }
};

export const reducers = combineReducers({
  agency: agencyReducer,
  agencies: agenciesReducer,
  pointings: pointingsReducer,
  user: userReducer,
  users: usersReducer,
});

export type AppState = ReturnType<typeof reducers>;
