import {
  IonButton,
  IonCol,
  IonDatetime,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonRow,
  IonTextarea,
  IonToast,
} from '@ionic/react';
import React, { useEffect, useState } from 'react';
import { connect } from '../data/connect';
import { format } from 'date-fns';

import './UserForm.scss';
import { car, clipboard, create, time, wallet, water, swap } from 'ionicons/icons';
import { postPointing, updatePointing } from '../data/api/pointing';
import ScheduleForm from './ScheduleForm';
import BountyListForm from './BountyListForm';
import { fetchBounties } from '../data/agency/agency.actions';
import { Bounty, BountyValue } from '../models/Bounty';
import { getUser, getUserData } from '../data/api/user';
import { getBounties } from '../data/api/bounty';
import { Pointing } from '../models/Pointing';
import UserInput from './UserInput';
import { getActivities, getTransferts } from '../data/selectors';
import { Activity } from '../models/Activity';
import { validateEmail } from '../util/validator';
import TransfertListForm from './TransfertListForm';
import moment from 'moment';

interface OwnProps {
  value?: Pointing;
  onCreate?: (result: any, toSigned: boolean) => any;
  onUpdate?: (update: any, toSigned: boolean) => any;
  formRef?: (formAction: { form: any; clean: any; setForm: any; }) => any;
  update?: number;
  readOnly?: boolean;
  token?: string;
  isAdmin?: boolean;
  idAgency?: number;
  actionsDisabled?: boolean;
}


interface DispatchProps {
  fetchBounties: typeof fetchBounties;
}

interface StateProps {
  bounties: Bounty[];
  activities: Activity[];
  infos: any;
}

interface Form {
  date: string;
  constructionSitename: string;
  constructionSiteNumber: string;
  activity: { startDate: string; endDate: string; idActivity: number; }[];
  bounty: any[];
  transfert: any[];
  fuelQuantity: number;
  fuelProvider: string;
  vehicleName: string;
  vehicleNumber: string;
  machineName: string;
  machineNumber: string;
  validatorName: string;
  validatorEmail: string;
  comment: string;
  idUser?: number;
}

enum ToastText {
  CONSTRUCTION_SITE= 'Vous devez renseigner le nom ou le numéro du chantier',
  SCHEDULE= 'Vous devez ajouter au moins un type d\'heure',
  SCHEDULE_NOT_COMPLETE = 'Vous n\'avez pas rempli tout les champs d\'un type d\'heure',
  SCHEDULE_OVERLAP = 'Les types d\'heure ne doivent pas se chevaucher',
  EMAIL= 'Vous devez renseigner l\'email du validateur',
  EMAIL_INCORRECT = 'Vous devez renseigner un email valide',
  POST_ERROR = 'Une erreur est survenue',
  TRANSFERT = 'Vous n\' avez pas remplit tout les champs d\'une zone de transfert'
}

enum ErrorField {
  CONSTRUCTION_SITE = 'constructionSite',
  SCHEDULE= 'schedule',
  TRANSFERT = 'transfert',
  EMAIL= 'email',
}

const PointingForm: React.FC<OwnProps & DispatchProps & StateProps> = ({ onCreate, formRef, isAdmin = false, readOnly = false, idAgency, actionsDisabled, onUpdate, infos, bounties, activities, update = 0 }) => {

  const startDate = new Date();
  startDate.setHours(7);
  startDate.setMinutes(0);

  const initialState = {
    date: format(new Date().getTime(), 'yyyy-MM-dd'),
    constructionSitename: '',
    constructionSiteNumber: '',
    activity: [{ idActivity: 0, startDate: moment(startDate.getTime()).format('YYYY-MM-DDTHH:mm:00.000Z'), endDate: '' }],
    bounty: [],
    transfert: [],
    fuelQuantity: 0,
    fuelProvider: '',
    vehicleName: '',
    vehicleNumber: '',
    validatorName: '',
    machineName: '',
    machineNumber: '',
    comment: '',
    validatorEmail: ''
  };
  const [form, setForm] = useState(initialState as Form);
  let setInputUserValue: any = null;
  if (formRef) {
    formRef({ form, setForm, clean: () => {
      setForm({
        ...initialState,
        bounty: bounties,
        vehicleName: infos.carName || '',
        vehicleNumber: infos.carNumber || '',
        machineName: infos.machineName || '',
        machineNumber: infos.machineNumber || ''
      });
      if (setInputUserValue) setInputUserValue('');
    } });
  }

  useEffect(() => {
    if (readOnly) return;
    getUserData().then(async ({ agency, infos }) => {
      const bounties = await getBounties(idAgency || agency);
      setForm({
        ...form,
        bounty: bounties,
        vehicleName: infos.carName || '',
        vehicleNumber: infos.carNumber || '',
        machineName: infos.machineName || '',
        machineNumber: infos.machineNumber || ''
      });
    });
  }, []);

  const [showToast, setShowToast] = useState(false);
  const [dateTimeTrick, setDateTimeTrick] = useState(false);
  const [toastText, setToastText] = useState('');

  const [formError, setFormError] = useState({
    constructionSite: false,
    schedule: false,
    email: false,
  });

  const setToastError = (field: ErrorField | string, text: ToastText) => {
    setToastText(text);
    setShowToast(true);
    setFormError({ ...formError, [field]: true });
  };

  const checkForm = () => {
    if (!form.constructionSitename.length && !form.constructionSiteNumber.length) {
      setToastError(ErrorField.CONSTRUCTION_SITE, ToastText.CONSTRUCTION_SITE);
      return false;
    }
    const someActivityNotFullFilled = form.activity.some(a => !a.idActivity || !a.startDate.length || !a.endDate.length);
    if (!form.activity.length || someActivityNotFullFilled) {
      setToastError(ErrorField.SCHEDULE, someActivityNotFullFilled ? ToastText.SCHEDULE_NOT_COMPLETE : ToastText.SCHEDULE);
      return false;
    }

    const someActivityOverlap = form.activity.some((activity, i) => {
      if (i === 0) return false;
      const prev = form.activity[i - 1];
      if (new Date(activity.startDate).getTime() < new Date(prev.endDate).getTime()) return true;
      return false;
    });

    if (someActivityOverlap) {
      setToastError(ErrorField.SCHEDULE, ToastText.SCHEDULE_OVERLAP);
      return false;
    }

    const someTransfertNotFullFilled = form.transfert.some(a => !a.id_transfert || !a.date.length || !a.fournisseur.length);
    if (form.activity.length && someTransfertNotFullFilled) {
      setToastError(ErrorField.TRANSFERT, ToastText.TRANSFERT);
      return false;
    }

    if (!form.validatorEmail.length) {
      setToastError(ErrorField.EMAIL, ToastText.EMAIL);
      return false;
    }

    if (!validateEmail(form.validatorEmail)) {
      setToastError(ErrorField.EMAIL, ToastText.EMAIL_INCORRECT);
      return false;
    }

    return true;
  };

  const createPointing = async (toSigned: boolean) => {
    if (!checkForm()) return;

    const payload = {
      ...form.idUser ? { idUser: form.idUser } : {},
      willBeSigned: toSigned,
      date: form.date,
      constructionSitename: form.constructionSitename,
      constructionSiteNumber: form.constructionSiteNumber,
      fuelQuantity: form.fuelQuantity,
      fuelProvider: form.fuelProvider,
      validatorName: form.validatorName,
      validatorEmail: form.validatorEmail,
      vehicleName: form.vehicleName,
      vehicleNumber: form.vehicleNumber,
      machineName: form.machineName,
      machineNumber: form.machineNumber,
      comment: form.comment,
      activity: form.activity.map(activity => ({
        startDate: activity.startDate,
        endDate: activity.endDate,
        id_activity: activity.idActivity
      })),
      bounty_value: form.bounty.map((bounty: Bounty) => ({
        id_bounty_value: bounty.idBountyValue || bounty.bountyValues.find((v: BountyValue) => v.isDefault)!.id
      })),
      transfert: form.transfert
    };

    const { data: result } = update ? await updatePointing(update, payload) : await postPointing(payload);
    if (!result || !result.status) return setToastError('', ToastText.POST_ERROR);
    if (update && onUpdate) return onUpdate(result, toSigned);
    if (!onCreate) return;
    onCreate(result.data.pointing, toSigned);
  };

  return (
    <div style={{ padding: '16px' }}>
      <IonList>
        { isAdmin && <UserInput
          idAgency={idAgency || 0}
          idRole={3}
          inputRef={({ setValue }) => { setInputUserValue = setValue; }}
          onSelect={ async (idUser) => {
            const { status, data } = await getUser(idUser);
            if (!status) return;
            const { carName, carNumber, machineName, machineNumber } = data.user;
            setForm({
              ...form,
              idUser,
              vehicleName: carName || '',
              vehicleNumber: carNumber || '',
              machineName: machineName || '',
              machineNumber: machineNumber || ''
            });
          }}/> }
        <IonItem>
          <IonLabel position="stacked" color="primary">Date pointage *</IonLabel>
          <IonDatetime
            cancelText="annuler"
            doneText="confirmer"
            readonly={readOnly}
            monthShortNames="jan, fev, mar, avr, mai, juin, juil, aoû, sep, oct, nov, dec"
            value={form.date}
            onFocus={() => setDateTimeTrick(true)}
            onIonChange={(e) => {
              if (!dateTimeTrick) return;
              setForm({ ...form, date: e.detail.value! });
              setDateTimeTrick(false);
            }}
          />
        </IonItem>
        <IonItem>
          <IonLabel position="stacked" color={formError.constructionSite ? 'danger' : 'primary'}>Numéro du chantier *</IonLabel>
          <IonInput readonly={readOnly} placeholder="MUMU XXX CCCCCC" value={form.constructionSiteNumber} onIonChange={(e) => setForm({ ...form, constructionSiteNumber: e.detail.value! })}/>
        </IonItem>
        <IonItem>
          <IonLabel position="stacked" color={ formError.constructionSite ? 'danger' : 'primary' }>Nom du chantier *</IonLabel>
          <IonInput readonly={readOnly} value={form.constructionSitename} onIonChange={(e) => setForm({ ...form, constructionSitename: e.detail.value! })}/>
        </IonItem>
        <IonListHeader>
          <h4 style={{ verticalAlign: 'center' }}><IonIcon color="primary" icon={car}/> Véhicule</h4>
        </IonListHeader>
        <IonItem>
          <IonLabel position="stacked" color="primary">Nom du véhicule</IonLabel>
          <IonInput readonly={readOnly} value={form.vehicleName} onIonChange={(e) => setForm({ ...form, vehicleName: e.detail.value! })}/>
        </IonItem>
        <IonItem>
          <IonLabel position="stacked" color="primary">Numéro du véhicule</IonLabel>
          <IonInput readonly={readOnly} value={form.vehicleNumber} onIonChange={(e) => setForm({ ...form, vehicleNumber: e.detail.value! })}/>
        </IonItem>
        <IonItem>
          <IonLabel position="stacked" color="primary">Nom d&apos;engin</IonLabel>
          <IonInput readonly={readOnly} value={form.machineName} onIonChange={(e) => setForm({ ...form, machineName: e.detail.value! })}/>
        </IonItem>
        <IonItem>
          <IonLabel position="stacked" color="primary">Numéro d&apos;engin</IonLabel>
          <IonInput readonly={readOnly} value={form.machineNumber} onIonChange={(e) => setForm({ ...form, machineNumber: e.detail.value! })}/>
        </IonItem>
        <IonListHeader>
          <h4 style={{ verticalAlign: 'center' }}><IonIcon color="primary" icon={time}/> Vos horaires</h4>
        </IonListHeader>
        <ScheduleForm readonly={readOnly} value={form.activity as any} onChange={(data: any) => setForm({ ...form, activity: data })}/>
        <IonListHeader>
          <h4 style={{ verticalAlign: 'center' }}><IonIcon color="primary" icon={wallet}/> Vos primes</h4>
        </IonListHeader>
        <BountyListForm readonly={readOnly} value={form.bounty} onChange={(data: any) => setForm({ ...form, bounty: data })}/>
        <IonListHeader>
          <h4 style={{ verticalAlign: 'center' }}><IonIcon color="primary" icon={swap}/> Transferts et divers</h4>
        </IonListHeader>
        <TransfertListForm readonly={readOnly} value={form.transfert} onChange={(data: any) => setForm({ ...form, transfert: data })}/>
        <IonListHeader>
          <h4 style={{ verticalAlign: 'center' }}><IonIcon color="primary" icon={water}/> Carburant</h4>
        </IonListHeader>
        <IonItem>
          <IonLabel position="stacked" color="primary">Quantité (Litres)</IonLabel>
          <IonInput readonly={readOnly} type="number" value={(form.fuelQuantity || 0).toString()} onIonChange={(e) => setForm({ ...form, fuelQuantity: Number(e.detail.value!) })}/>
        </IonItem>
        <IonItem>
          <IonLabel position="stacked" color="primary">Fournisseur</IonLabel>
          <IonInput readonly={readOnly} value={form.fuelProvider} onIonChange={(e) => setForm({ ...form, fuelProvider: e.detail.value! })}/>
        </IonItem>
        <IonListHeader>
          <h4 style={{ verticalAlign: 'center' }}><IonIcon color="primary" icon={clipboard}/> Commentaire</h4>
        </IonListHeader>
        <IonItem>
          <IonTextarea readonly={readOnly} value={form.comment} onIonChange={(e) => setForm({ ...form, comment: e.detail.value! })}/>
        </IonItem>
        <IonListHeader>
          <h4 style={{ verticalAlign: 'center' }}><IonIcon color="primary" icon={create}/> Validation</h4>
        </IonListHeader>
        <IonItem>
          <IonLabel position="stacked" color="primary">Nom</IonLabel>
          <IonInput readonly={readOnly} type="search" autocomplete="on" value={form.validatorName} onIonChange={(e) => setForm({ ...form, validatorName: e.detail.value! })}/>
        </IonItem>
        <IonItem>
          <IonLabel position="stacked" color={formError.email ? 'danger' : 'primary'}>Email *</IonLabel>
          <IonInput readonly={readOnly} autocomplete="on" type="email" value={form.validatorEmail} onIonChange={(e) => setForm({ ...form, validatorEmail: e.detail.value! })}/>
        </IonItem>
        <br/>
        <br/>
      </IonList>
      { !actionsDisabled &&
        <IonRow>
          <IonCol>
            <IonButton expand="block" onClick={() => createPointing(true)}>Valider et signer</IonButton>
          </IonCol>
          <IonCol>
            <IonButton expand="block" color="warning" onClick={() => createPointing(false)}>Valider par email</IonButton>
          </IonCol>
        </IonRow>
      }
      { update !== 0 &&
        <IonRow>
          <IonCol>
            <IonButton type="submit" expand="block" color="warning" onClick={() => createPointing(false)}>Enregister</IonButton>
          </IonCol>
          <IonCol>
            <IonButton type="submit" expand="block" onClick={() => createPointing(true)}>Enregister et signer</IonButton>
          </IonCol>
        </IonRow>
      }
      <IonToast
        isOpen={showToast}
        message={toastText}
        duration={2000}
        onDidDismiss={() => setShowToast(false)}
      />
    </div>
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    bounties: state.agency.bounties,
    activities: getActivities(state),
    transferts: getTransferts(state),
    infos: state.user.infos
  }),
  component: React.memo(PointingForm)
});
