import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCol,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonRadio,
  IonRadioGroup,
  IonRow,
  IonText,
  IonToast,
} from '@ionic/react';
import React, { FormEvent, useState } from 'react';
import { connect } from '../data/connect';

import './UserForm.scss';
import { add, trash } from 'ionicons/icons';
import { postBounty } from '../data/api/bounty';

interface BountyFormProps {
  children?: React.ReactNode;
  title?: string;
  onCreate?: () => any;
  agency: number;
  formRef?: (formAction: { form: any; clean: any; setForm: any; }) => any;
  update?: boolean;
}


interface DispatchProps {}

interface Form {
  name: string;
  bountyValues: { name: string; isDefault: boolean; }[];
}

const BountyForm: React.FC<BountyFormProps & DispatchProps> = ({ agency, onCreate, formRef, update = false }) => {

  const initialForm = {
    name: '',
    bountyValues: []
  } as Form;

  const [form, setForm] = useState({ ...initialForm });
  const [value, setValue] = useState('');
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [nameError, setNameError] = useState(false);
  const [showCompleteToast, setShowCompleteToast] = useState(false);
  const [toastText, setToastText] = useState('');

  if (formRef) {
    formRef({ form, setForm, clean: () => setForm({ ...initialForm }) });
  }

  const createBounty = async (e: FormEvent) => {
    e.preventDefault();
    setFormSubmitted(true);
    const { data: result } = await postBounty(agency, form);
    if (!result.status) {
      setToastText('Erreur d\'enregistrement');
      return setShowCompleteToast(true);
    }
    setToastText(result.data.alert.message);
    if (onCreate) onCreate();
    return setShowCompleteToast(true);
  };

  const addValue = (value: string) => {
    setNameError(!value.length);
    if (!value.length) return;

    const isAlreadyInserted = form.bountyValues.some(u => u.name === value);
    if (isAlreadyInserted) {
      return setNameError(true);
    }

    setForm({
      ...form,
      bountyValues: [
        ...form.bountyValues,
        { name: value, isDefault: !form.bountyValues.length }
      ]
    });
    setValue('');
  };

  const removeBounty = (value: string) => {
    const bountyValues = form.bountyValues.filter(u => u.name !== value);
    setForm({
      ...form,
      bountyValues
    });
  };

  const changeValue = (oldValue: string, newValue: string) => {
    const bountyValues = form.bountyValues.map(u => ({
      name: oldValue === u.name ? newValue : u.name,
      isDefault: u.isDefault
    }));
    setForm({
      ...form,
      bountyValues
    });
  };

  const setDefault = (name: string, isDefault: boolean) => {
    const bountyValues = form.bountyValues.map(u => ({
      ...u,
      isDefault: u.name === name ? isDefault : false
    }));
    setForm({
      ...form,
      bountyValues
    });
  };

  return (
    <form noValidate onSubmit={createBounty} style={{ padding: '16px' }}>
      <IonList>
        <IonItem>
          <IonLabel position="stacked" color="primary">Nom de la prime *</IonLabel>
          <IonInput name={'name'} spellCheck={false} required value={form.name} onIonChange={(e) => setForm({ ...form, name: e.detail.value! })}/>
        </IonItem>
        {formSubmitted && nameError && <IonText color="danger">
          <p className="ion-padding-start">
                  Le nom est requis
          </p>
        </IonText>}
        <IonList>
          <IonCard>
            <IonCardHeader>
              <IonCardSubtitle>
                Ajouter une nouvelle valeur
              </IonCardSubtitle>
            </IonCardHeader>
            <IonCardContent>
              <IonItem>
                <IonInput
                  placeholder={'Insérer votre valeur'}
                  type={'text'}
                  name={'text'}
                  value={value}
                  onIonChange={(e) => setValue(e.detail.value!)}
                  color={nameError ? 'danger' : ''}
                  spellCheck={false}
                  required/>
                <IonButton icon-only fill="clear" onClick={() => addValue(value)}>
                  <IonIcon slot="icon-only" icon={add} color={'primary'}/>
                </IonButton>
              </IonItem>
            </IonCardContent>
          </IonCard>
          { !!form.bountyValues.length &&
          <IonRow>
            <IonCol>
              <b>Par défaut</b>
            </IonCol>
            <IonCol/>
            <IonCol/>
          </IonRow>}
          <IonRadioGroup>
            { form.bountyValues.map((bounty, i) => <IonItem key={`bounty-${i}`}>
              <IonInput
                type={'text'}
                name={'text'}
                value={bounty.name}
                debounce={200}
                onIonChange={(e) => changeValue(bounty.name, e.detail.value!)}
                onIonBlur={() => {
                  if (bounty.name.length) return;
                  return removeBounty(bounty.name);
                }}
                spellCheck={false}
                required/>
              <IonRadio slot="start" checked={bounty.isDefault} onClick={() => setDefault(bounty.name, true)} />
              <IonButton icon-only fill="clear" onClick={() => removeBounty(bounty.name)}>
                <IonIcon slot="icon-only" icon={trash} color={'danger'} />
              </IonButton>
            </IonItem>)}
          </IonRadioGroup>
        </IonList>
      </IonList>
      <IonRow>
        <IonCol>
          <IonButton type="submit" expand="block">Ajouter</IonButton>
        </IonCol>
      </IonRow>
      <IonToast
        isOpen={showCompleteToast}
        message={toastText}
        duration={2000}
        onDidDismiss={() => setShowCompleteToast(false)}
      />
    </form>
  );
};

export default connect<BountyFormProps, {}, DispatchProps>({
  mapStateToProps: () => ({}),
  component: React.memo(BountyForm)
});
