import React, { useEffect, useRef, useState } from 'react';
import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonLoading,
  IonMenuButton,
  IonPage,
  IonRefresher,
  IonRefresherContent,
  IonSegment,
  IonSegmentButton,
  IonToast,
  IonToolbar
} from '@ionic/react';
import { connect } from '../data/connect';
import PointingList from '../components/PointingList';
import './PointingPage.scss';
import AddPointingFab from '../components/AddPointingFab';
import { fetchPointings, setPointingState } from '../data/pointings/pointings.actions';
import { RouteComponentProps, withRouter } from 'react-router';
import { PointingState } from '../util/types';
import {getPointing, postSend, postValidation} from '../data/api/pointing';
import { fetchActivities, fetchBounties, fetchTransferts } from '../data/agency/agency.actions';
import ShareModal from './ShareModal';

interface OwnProps { }

interface StateProps {
  pointingState: PointingState;
  role: any;
}

const formatPointing = (pointing: any) => ({
  date: pointing.date,
  constructionSitename: pointing.constructionSitename,
  constructionSiteNumber: pointing.constructionSiteNumber,
  activity: pointing.activity.map((activity: any) => ({
    idActivity: Number(activity.id_activity),
    startDate: activity.startDate,
    endDate: activity.endDate
  })),
  transfert: pointing.transfert || [],
  bounty: pointing.bountyValue,
  fuelQuantity: pointing.fuelQuantity,
  fuelProvider: pointing.fuelProvider,
  vehicleName: pointing.vehicleName,
  vehicleNumber: pointing.vehicleNumber,
  validatorName: pointing.validatorName,
  machineName: pointing.machineName,
  machineNumber: pointing.machineNumber,
  comment: pointing.comment,
  validatorEmail: pointing.validatorEmail
});

interface DispatchProps {
  fetchPointings: typeof fetchPointings;
  setPointingState: typeof setPointingState;
  fetchActivities: typeof fetchActivities;
  fetchBounties: typeof fetchBounties;
  fetchTransferts: typeof fetchTransferts;
}

type PointingPageProps = OwnProps & StateProps & DispatchProps & RouteComponentProps;

const PointingPage: React.FC<PointingPageProps> = ({ location, fetchPointings, fetchTransferts, setPointingState, pointingState, fetchBounties, fetchActivities, role }) => {
  const [showLoading, setShowLoading] = useState(false);
  const ionRefresherRef = useRef<HTMLIonRefresherElement>(null);
  const [showCompleteToast, setShowCompleteToast] = useState(false);
  const [showPointingForm, setShowPointingForm] = useState(false);
  const [shareModal, setShareModal] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState('');
  const [pointingShared, setPointingShared] = useState(0);
  const [showToast, setShowToast] = useState(false);
  const [message, setMessage] = useState('');
  const idAgency = Number(new URLSearchParams(location.search).get('agency')) || 0;
  const copy = Number(new URLSearchParams(location.search).get('copy')) || 0;
  let setPointingForm: any = null;
  let setDisable: any = null;

  const doRefresh = () => {
    fetchPointings(1, true, idAgency, pointingState);
    setTimeout(() => {
      ionRefresherRef.current!.complete();
      setShowCompleteToast(true);
      setDisable(false);
    }, 1000);
  };

  const changePointing = (state: PointingState) => {
    setPointingState(state);
    fetchPointings(1, true, idAgency, state);
  };

  const copyPointing = (id: number) => {
    setShowLoading(true);
    getPointing(id).then((pointing) => {
      if (!pointing) return setShowLoading(false);
      setTimeout(() => {
        setPointingForm(formatPointing(pointing));
        setShowLoading(false);
        setLoadingMessage('copie en cours...');
        setShowPointingForm(true);
      }, 1000);
    });
  };

  const sharePointing = async (email: string) => {
    setLoadingMessage('Envoi en cours...');
    setShowLoading(true);
    const { data: result, status } = await postSend(pointingShared, email);
    if (status !== 200 || !result.status) {
      setMessage('Une erreur est survenue');
      setShowToast(true);
      return setShowLoading(false);
    }
    setMessage('L\'email a été envoyé');
    setTimeout(() => {
      setShowToast(true);
      setShareModal(false);
      return setShowLoading(false);
    }, 500);
  };

  useEffect(() => {
    setTimeout(() => {
      fetchActivities(idAgency);
      fetchBounties(idAgency);
      fetchTransferts(idAgency);
      fetchPointings(1, true, idAgency, pointingState);
    }, 25);
    if (copy) {
      copyPointing(copy);
    }
  }, []);

  return (
    <IonPage id="pointing-page">
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            { role && role.id === 1 ?
              <IonBackButton defaultHref={`/tabs/agency/${idAgency}`}/> :
              <IonMenuButton />
            }
          </IonButtons>
          <IonSegment>
            <IonSegmentButton onClick={() => changePointing(PointingState.all)} value={'1'} checked={pointingState === PointingState.all}>
              Tout
            </IonSegmentButton>
            <IonSegmentButton onClick={() => changePointing(PointingState.pending)} value={'2'} checked={pointingState === PointingState.pending}>
              Attente
            </IonSegmentButton>
            <IonSegmentButton onClick={() => changePointing(PointingState.validated)} value={'3'} checked={pointingState === PointingState.validated}>
              Validé
            </IonSegmentButton>
            <IonSegmentButton onClick={() => changePointing(PointingState.refused)} value={'4'} checked={pointingState === PointingState.refused}>
              Refusé
            </IonSegmentButton>
          </IonSegment>
        </IonToolbar>
      </IonHeader>

      <IonContent>
        <IonRefresher slot="fixed" ref={ionRefresherRef} onIonRefresh={doRefresh}>
          <IonRefresherContent />
        </IonRefresher>
        <IonToast
          isOpen={showCompleteToast}
          message="Contenu rafraichi"
          duration={2000}
          onDidDismiss={() => setShowCompleteToast(false)}
        />
        <PointingList
          disable={(disable: any) => setDisable = disable}
          idAgency={idAgency}
          onCopy={(value: any) => copyPointing(value)}
          onShare={(value: any) => {
            setPointingShared(value);
            setShareModal(true);
          }}
        />
      </IonContent>
      <AddPointingFab idAgency={idAgency} isOpen={showPointingForm} onClose={() => setShowPointingForm(false)} formRef={({ setForm }) => setPointingForm = setForm} />
      <ShareModal header={'Partager le pointage'} isopen={shareModal} onClose={() => setShareModal(false)} onSubmit={(email) => sharePointing(email)}/>
      <IonLoading
        isOpen={showLoading}
        message={loadingMessage}
        spinner="crescent"
        onDidDismiss={() => setShowLoading(false)}
      />
      <IonToast
        isOpen={showToast}
        message={message}
        duration={2000}
        onDidDismiss={() => setShowToast(false)}
      />
    </IonPage>
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    pointingState: state.pointings.state,
    role: state.user.role
  }),
  mapDispatchToProps: {
    fetchPointings,
    setPointingState,
    fetchActivities,
    fetchBounties,
    fetchTransferts
  },
  component: withRouter(PointingPage)
});
