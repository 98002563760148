import React, { useState } from 'react';
import {
  IonButton,
  IonButtons, IonCol,
  IonContent,
  IonHeader, IonInput,
  IonItem,
  IonLabel,
  IonModal, IonRow, IonText,
  IonTitle,
  IonToolbar
} from '@ionic/react';

interface OwnProps {
  onSubmit?: (email: string) => any;
  onClose?: () => any;
  isopen?: boolean;
  header: string;
}

const ShareModal: React.FC<OwnProps> = ({ header, onSubmit, isopen = false, onClose }) => {
  const [email, setEmail] = useState('');
  const [error, setError] = useState(false);

  return (
    <IonModal isOpen={isopen} onDidDismiss={() => onClose && onClose()}>
      <IonHeader translucent>
        <IonToolbar>
          <IonTitle>Partager le pointage</IonTitle>
          <IonButtons color={'danger'} slot="end" onClick={() => onClose && onClose()}>
            <IonButton>
              Fermer
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonItem class="ion-padding">
          <IonLabel position="stacked" color="primary">Email *</IonLabel>
          <IonInput name="email" type="email" value={email} spellCheck={false} autocapitalize="off" onIonChange={e => {
            setEmail(e.detail.value!);
          }} required />
          {error && <IonText color="danger">
            <p className="error-message">
                    L&apos;email est requis
            </p>
          </IonText>}
        </IonItem>
        <IonRow>
          <IonCol>
            <IonButton type="submit" expand="block" onClick={() => {
              if (!email.length) {
                return setError(true);
              }
              return onSubmit && onSubmit(email);
            }}>Partager</IonButton>
          </IonCol>
        </IonRow>
      </IonContent>
    </IonModal>
  );
};

export default React.memo(ShareModal);
