import { AgencyActions } from './agency.actions';
import { AgencyState } from './agency.state';

export const agencyReducer = (state: AgencyState, action: AgencyActions): AgencyState => {
  switch (action.type) {
    case 'set-agency-loading': {
      return { ...state, loading: action.isLoading };
    }
    case 'set-agency':
      return { ...state, ...action.agency };
    case 'set-notification':
      return { ...state, notifications: action.notifications };
    case 'set-activity':
      return { ...state, activities: action.activities };
    case 'set-bounty':
      return { ...state, bounties: action.bounties };
    case 'set-transfert':
      return { ...state, transferts: action.transferts };
  }
};
