import React, { useState, useRef, useEffect } from 'react';
import {
  IonList,
  IonItem,
  IonIcon,
  IonButton, IonLabel, IonListHeader,
} from '@ionic/react';
import { trash } from 'ionicons/icons';
import { Bounty } from '../models/Bounty';
import { connect } from '../data/connect';
import AddBountyFab from './AddBountyFab';
import { deleteBounty } from '../data/api/bounty';
import { fetchBounties } from '../data/agency/agency.actions';

import './BountyList.scss';
import TrashButton from "./TrashButton";

interface OwnProps {
  bountyList: Bounty[];
  onDelete?: () => any;
  idAgency: number;
}

type BountyListProps = OwnProps;

const BountyList: React.FC<BountyListProps> = ({ bountyList, onDelete, idAgency }) => {
  const removeBounty = async (id: number) => {
    await deleteBounty(id);
    await fetchBounties(idAgency);
    if (onDelete) {
      onDelete();
    }
  };

  return (
    <>
      <IonList>
        <IonListHeader>
          { bountyList.length ? <h4>Liste des primes</h4> : <p>Aucune primes trouvées</p> }
        </IonListHeader>
        {bountyList.map((bounty) => <IonItem key={bounty.id} style={{
          marginBottom: '5px'
        }}>
          <IonLabel>
            <h2>{bounty.name}</h2>
            {
              bounty.bountyValues.map((u) => <span className="bountyDescription" key={`bounty-${u.value}-${u.id}`}>
                {u.value}&nbsp;
              </span>)
            }
          </IonLabel>
          <TrashButton
            message={`Voulez-vous supprimer <strong>${bounty.name}</strong> ?`}
            header={'Supprimer une prime'}
            onDelete={() => removeBounty(bounty.id)}
          />
        </IonItem>)}
      </IonList>
      <AddBountyFab idAgency={idAgency} />
    </>
  );
};

export default connect<OwnProps, {}, {}>({
  component: React.memo(BountyList)
});
