import {
  IonButton, IonList, IonItem, IonLabel, IonInput, IonRow, IonCol, IonText
} from '@ionic/react';
import React, { FormEvent, useState } from 'react';
import AddFab from './AddFab';
import { postAgency } from '../data/api/agency';
import { fetchAgencies } from '../data/agencies/agencies.actions';
import { connect } from '../data/connect';

interface AddAgencyFabProps {
  children?: React.ReactNode;
  title?: string;
}


interface DispatchProps {
  fetchAgencies: typeof fetchAgencies;
}

const AddAgencyFab: React.FC<AddAgencyFabProps & DispatchProps> = ({ fetchAgencies }) => {

  const [name, setName] = useState('');
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [nameError, setNameError] = useState(false);
  let closeModal: any = null;

  const onClose = () => {
    setName('');
    setNameError(false);
  };

  const createAgency = async (e: FormEvent) => {
    e.preventDefault();
    setFormSubmitted(true);
    if (!name) {
      setNameError(true);
    }
    const { data: result } = await postAgency({
      name
    });
    if (!result.status) { return; }
    fetchAgencies(1, true);
    onClose();
    closeModal();
  };

  return (
    <AddFab title={'Créer une agence'} onClose={onClose} close={(close: any) => closeModal = close}>
      <form noValidate onSubmit={createAgency} style={{ padding: '16px' }}>
        <IonList>
          <IonItem>
            <IonLabel position="stacked" color="primary">Nom de l&apos;agence</IonLabel>
            <IonInput name={'name'} spellCheck={false} value={name} required onIonChange={(e) => setName(e.detail.value!)}/>
          </IonItem>
          {formSubmitted && nameError && <IonText color="danger">
            <p className="ion-padding-start">
                  Le nom est requis
            </p>
          </IonText>}
        </IonList>
        <IonRow>
          <IonCol>
            <IonButton type="submit" expand="block">Créer</IonButton>
          </IonCol>
        </IonRow>
      </form>
    </AddFab>
  );
};

export default connect<{}, {}, DispatchProps>({
  mapStateToProps: () => ({}),
  mapDispatchToProps: {
    fetchAgencies
  },
  component: React.memo(AddAgencyFab)
});
