export const HAS_LOGGED_IN = 'hasLoggedIn';
export const HAS_SEEN_TUTORIAL = 'hasSeenTutorial';
export const IS_STORED = 'isStored';
export const USERNAME = 'username';
export const TOKEN = 'token';
export const USER_ROLE = 'userRole';
export const ROLES = 'roles';
export const USER_INFO = 'USER_INFO';
export const USER_AGENCY = 'userAgency';
export const DARK_MODE = 'darkMode';
