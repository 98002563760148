import axios from '../axios';

export const postTransfert = async (idAgency: number, name: string) => {
  try {
    return await axios.post(`/agence/addTransfert/${idAgency}`, { name });
  } catch (e) {
    return e.response;
  }
};

export const deleteTransfert = async (idTransfert: number) => {
  try {
    return await axios.delete(`/agence/removeTransfert/${idTransfert}`);
  } catch (e) {
    return e.response;
  }
};

export const getTransferts = async (id: number, isDeleted?: boolean) => {
  const { data: result } = await axios.get(`/agence/findAllTransfert/${id}`, {
    params: {
      isDeleted
    }
  });
  return result.data;
};
