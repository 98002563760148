import React, { useState, useRef, useEffect } from 'react';
import {
  IonToolbar,
  IonContent,
  IonPage,
  IonButtons,
  IonMenuButton,
  IonRefresher,
  IonRefresherContent,
  IonToast,
  IonHeader,
  IonTitle
} from '@ionic/react';
import { connect } from '../data/connect';
import './AgencyPage.scss';
import AgencyList from '../components/AgencyList';
import AddAgencyFab from '../components/AddAgencyFab';
import { fetchAgencies } from '../data/agencies/agencies.actions';

// interface OwnProps { }

interface StateProps {
  // sessionGroups: SessionGroup[];
  // favoriteGroups: SessionGroup[];
  // mode: 'ios' | 'md';
}

interface DispatchProps {
  fetchAgencies: typeof fetchAgencies;
}

type AgencyPageProps = StateProps & DispatchProps;

const AgencyPage: React.FC<AgencyPageProps> = ({ fetchAgencies }) => {
  const ionRefresherRef = useRef<HTMLIonRefresherElement>(null);
  const [showCompleteToast, setShowCompleteToast] = useState(false);
  let setDisable: any = null;

  useEffect(() => {
    setTimeout(() => {
      fetchAgencies(1, true);
    }, 250);
  }, []);

  const doRefresh = () => {
    fetchAgencies(1, true);
    setTimeout(() => {
      ionRefresherRef.current!.complete();
      setShowCompleteToast(true);
      setDisable(false);
    }, 1000);
  };

  return (
    <IonPage id="agency-page">
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>
            Liste des agences
          </IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonRefresher slot="fixed" ref={ionRefresherRef} onIonRefresh={doRefresh}>
          <IonRefresherContent />
        </IonRefresher>
        <IonToast
          isOpen={showCompleteToast}
          message="Contenu rafraichi"
          duration={2000}
          onDidDismiss={() => setShowCompleteToast(false)}
        />

        <AgencyList disable={(disable: any) => setDisable = disable}/>
      </IonContent>
      <AddAgencyFab/>
    </IonPage>
  );
};

export default connect<{}, StateProps, DispatchProps>({
  // mapStateToProps: () => ({
  //   sessionGroups: selectors.getGroupedSessions(state),
  //   favoriteGroups: selectors.getGroupedFavorites(state),
  //   mode: getConfig()!.get('mode')
  // }),
  mapDispatchToProps: {
    fetchAgencies
  },
  component: React.memo(AgencyPage)
});
