import React, { useEffect } from 'react';
import {
  IonHeader,
  IonToolbar,
  IonContent,
  IonPage,
  IonButtons,
  IonList,
  IonMenuButton,
} from '@ionic/react';
import { connect } from '../data/connect';
import { withRouter, RouteComponentProps } from 'react-router';
import { loadUserData } from '../data/user/user.actions';
import UserForm from '../components/UserForm';
import { getUserData } from '../data/api/user';
import {setIsStored} from "../data/storage/user";

type OwnProps = RouteComponentProps;

interface StateProps {
  infos: any;
}

interface DispatchProps {}

type AccountPageProps = OwnProps & StateProps & DispatchProps & RouteComponentProps;

const AccountPage: React.FC<AccountPageProps> = ({ infos }) => {

  let setUserForm: any = null;
  useEffect(() => {
    loadUserData();
    getUserData().then(({ infos }) => {
      if (setUserForm) setUserForm({
        id: infos.id,
        firstname: infos.firstname,
        lastname: infos.lastname,
        email: infos.email,
        phone: infos.phone,
        carName: infos.carName,
        carNumber: infos.carNumber,
        machineName: infos.machineName,
        machineNumber: infos.machineNumber
      });
    });
  }, []);

  return (
    <IonPage id="account-page">
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        { infos && <>
          <h1 className="ion-text-center">{infos.firstname} {infos.lastname}</h1>
        </>
        }
        <IonList>
          <UserForm onUpdate={async () => {
            await setIsStored(false);
            loadUserData();
          }} formRef={({ setForm }) => {
            setUserForm = setForm;
          }} update/>
        </IonList>
      </IonContent>
    </IonPage>
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    infos: state.user.infos,
  }),
  mapDispatchToProps: {},
  component: withRouter(AccountPage)
});
