import React, { useState } from 'react';
import {
  IonToast,
  IonList,
  IonInput,
  IonItem,
  IonIcon,
  IonButton,
  IonListHeader,
  IonCard,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle, IonCardContent, IonLabel
} from '@ionic/react';
import { connect } from '../data/connect';
import { add, trash } from 'ionicons/icons';
import { Notification } from '../models/Notification';
import { deleteNotification, postNotification } from '../data/api/notification';
import { fetchNotifications } from '../data/agency/agency.actions';
import { validateEmail } from '../util/validator';
import TrashButton from "./TrashButton";

interface OwnProps {
  notificationList: Notification[];
  idAgency: number;
}

interface DispatchProps {
  fetchNotifications: typeof fetchNotifications;
}

interface NotificationListProps extends OwnProps, DispatchProps {}

const NotificationList: React.FC<NotificationListProps> = ({ fetchNotifications, notificationList, idAgency }) => {
  const [showToast, setShowToast] = useState(false);
  const [toastText, setToastText] = useState('');
  const [notificationInput, setNotificationInput] = useState('');
  const [notificationInputError, setNotificationInputError] = useState(false);

  const addNotification = async (value: string) => {
    // Check if the email value isn't empty
    setNotificationInputError(!value.length);
    setToastText(value.length ? `${value} ajouté` : 'Le champs est vide');
    if (!value.length) return setShowToast(true);

    // Check if the email value is valid
    if (!validateEmail(value)) {
      setNotificationInputError(true);
      setShowToast(true);
      return setToastText(`${value} n'est pas un email valide`);
    }

    const { data: result } = await postNotification(idAgency, value);
    if (!result.status) {
      const errorText = result.data.errors.email || 'Une erreur est survenue';
      setNotificationInputError(true);
      setToastText(errorText);
      return setShowToast(true);
    }
    setToastText(result.data.alert.message);
    setNotificationInput('');
    // Refresh notificationList
    fetchNotifications(idAgency);
    return setShowToast(true);
  };

  const removeNotification = async (idNotification: number) => {
    let result = null;
    try {
      const { data } = await deleteNotification(idNotification);
      result = data;
    } catch (e) {
      result = e.response;
    }
    if (!result.status) {
      const errorText = 'Une erreur est survenue';
      setNotificationInputError(true);
      setToastText(errorText);
      return setShowToast(true);
    }

    setToastText(result.data.alert.message);
    // Refresh notificationList
    fetchNotifications(idAgency);
    return setShowToast(true);
  };

  return (
    <>
      <IonCard>
        <IonCardHeader>
          <IonCardSubtitle>Ajouter un email à notifier</IonCardSubtitle>
          <IonCardTitle>Notification</IonCardTitle>
        </IonCardHeader>
        <IonCardContent>
          <IonItem>
            <IonInput
              placeholder={'john.doe@example.com'}
              type={'email'}
              name={'email'}
              value={notificationInput}
              onIonChange={(e) => setNotificationInput(e.detail.value!)}
              color={notificationInputError ? 'danger' : ''}
              spellCheck={false}
              required/>
            <IonButton icon-only fill="clear" onClick={() => addNotification(notificationInput)}>
              <IonIcon slot="icon-only" icon={add} color={'primary'}/>
            </IonButton>
          </IonItem>
        </IonCardContent>
      </IonCard>
      <IonList>
        <IonListHeader>
          <p>{ notificationList.length ? 'Liste des emails notifiés' : 'Aucun emails '}</p>
        </IonListHeader>
        { !!notificationList.length &&
          notificationList.map((notification) => <IonItem key={notification.id} style={{
            marginBottom: '5px'
          }}>
            <IonLabel>
              {notification.value}
            </IonLabel>
            <TrashButton
              message={`Voulez-vous supprimer <strong>${notification.value}</strong> ?`}
              header={'Supprimer un email de notification'}
              onDelete={() => removeNotification(notification.id)}
            />
          </IonItem>)}
      </IonList>
      <IonToast
        isOpen={showToast}
        message={toastText}
        duration={2000}
        onDidDismiss={() => setShowToast(false)}
      />
    </>
  );
};

export default connect<OwnProps, {}, DispatchProps>({
  mapDispatchToProps: {
    fetchNotifications
  },
  component: React.memo(NotificationList)
});
