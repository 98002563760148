import {
  IonItemDivider,
  IonItemGroup,
  IonList,
  IonListHeader,
  IonInfiniteScroll,
  IonInfiniteScrollContent, IonToast,
} from '@ionic/react';

import React, { useState } from 'react';
import { connect } from '../data/connect';
import * as selectors from '../data/selectors';
import { User } from '../models/User';
import { Pagination } from '../models/Pagination';
import UserListItem from './UserListItem';
import { fetchUsers } from '../data/users/users.actions';
import { deleteUser } from '../data/api/user';

interface OwnProps {
  disable?: (disable: any) => any;
  idAgency: number;
}

interface StateProps {
  userList: { users: User[]; pagination: Pagination; };
}

interface DispatchProps {
  fetchUsers: typeof fetchUsers;
}

interface UserListProps extends OwnProps, StateProps, DispatchProps { }

const UserList: React.FC<UserListProps> = ({ userList, fetchUsers, disable, idAgency }) => {
  const [disabled, setDisabled] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');

  const displayToast = (message: string) => {
    setToastMessage(message);
    setShowToast(true);
  };

  if (disable) {
    disable(setDisabled);
  }

  const removeUser = async (id: number) => {
    const { data: result, status } = await deleteUser(id);
    if (!result || !status) return displayToast('Une erreur s\'est produite');
    fetchUsers(1, true, idAgency);
    return displayToast('L\'utilisateur a bien été supprimé');
  };

  if (!Array.isArray(userList.users) || userList.users.length === 0) {
    return (
      <IonList>
        <IonListHeader>
          Aucun utilisateurs trouvés
        </IonListHeader>
      </IonList>
    );
  }
  return (
    <>
      <IonList>
        {userList.users.length && userList.users.map((user, index: number) => <IonItemGroup key={`user-${index}`}>
          <IonItemDivider sticky>
            <UserListItem user={user} onRemove={(id) => removeUser(id)}/>
          </IonItemDivider>
        </IonItemGroup>)}
      </IonList>
      <IonInfiniteScroll disabled={disabled} onIonInfinite={(e) => {
        const currentPage = userList.pagination.currentPage;
        if (userList.pagination.totalPages === currentPage) {
          return setDisabled(true);
        }
        fetchUsers(currentPage + 1, false, idAgency);
        setTimeout(() => {
          // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
          // @ts-ignore
          e.target.complete();
        }, 500);
      }}>
        <IonInfiniteScrollContent
          loadingSpinner="bubbles"
          loadingText="Chargement..."
        />
        <IonToast
          isOpen={showToast}
          onDidDismiss={() => setShowToast(false)}
          message={toastMessage}
          duration={2000}
        />
      </IonInfiniteScroll>
    </>
  );
};

export default connect({
  mapStateToProps: (state) => ({
    // agencyList: state.data.favorites
    userList: selectors.getUsers(state),
  }),
  mapDispatchToProps: {
    fetchUsers
  },
  component: UserList
});
