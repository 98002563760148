import axios from '../axios';

export const postActivity = async (idAgency: number, name: string, isIncludeInTotalHours: boolean) => {
  try {
    return await axios.post(`/agence/addActivity/${idAgency}`, {
      name,
      isIncludeInTotalHours: Number(isIncludeInTotalHours)
    });
  } catch (e) {
    return e.response;
  }
};

export const deleteActivity = async (idActivity: number) => {
  try {
    return await axios.delete(`/agence/removeActivity/${idActivity}`);
  } catch (e) {
    return e.response;
  }
};

export const getActivities = async (id: number, isDeleted?: boolean) => {
  const { data: result } = await axios.get(`/agence/findAllActivity/${id}`, {
    params: {
      isDeleted
    }
  });
  return result.data;
};
