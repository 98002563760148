import { ActionType, PointingState } from '../../util/types';
import { getPointings } from '../api/pointing';
import { Pagination } from '../../models/Pagination';
import { Pointing } from '../../models/Pointing';
import { getUserData } from '../api/user';

export const setLoading = (isLoading: boolean) => ({
  type: 'set-pointing-loading',
  isLoading
} as const);

export const setPointings = (data: { pointings: Pointing[]; pagination: Pagination; }) => ({
  type: 'set-pointings',
  data
} as const);

export const addPointings = (data: {  pointings: Pointing[]; pagination: Pagination; }) => ({
  type: 'add-pointings',
  data
} as const);

export const setPointingState = (state: PointingState) => ({
  type: 'set-pointing-state',
  state
} as const);

export const fetchPointings = (page?: number, truncate = false, id?: number, state?: PointingState) => async (dispatch: React.Dispatch<any>) => {
  dispatch(setLoading(true));
  const idAgency = id || (await getUserData()).agency;
  const data = await getPointings(page, idAgency, state);
  dispatch(truncate ? setPointings(data) : addPointings(data));
  dispatch(setLoading(false));
};

export type PointingsActions =
  | ActionType<typeof setLoading>
  | ActionType<typeof addPointings>
  | ActionType<typeof setPointings>
  | ActionType<typeof setPointingState>
