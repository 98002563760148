import React, { useEffect, useState } from 'react';
import { connect } from '../data/connect';
import { Redirect } from 'react-router';
import { getCurrentUser } from '../data/selectors';
import { Plugins } from '@capacitor/core';

const { Device } = Plugins;

interface StateProps {
  user: any;
}

const HomeOrAgency: React.FC<StateProps> = ({ user }) => {

  const [platform, setPlatform] = useState('');

  useEffect(() => {
    Device.getInfo().then(({ platform }) => {
      setPlatform(platform);
    });
  }, [user]);

  if (platform === 'web' && user && user.role && user.role.id === 0) return <div>chargement...</div>;
  return user && user.role ? <Redirect to={user.role.id !== 1 ? '/tabs/pointing' : '/tabs/agency'}/> : <div>chargement...</div>;
};

export default connect<{}, StateProps, {}>({
  mapStateToProps: (state) => ({
    user: getCurrentUser(state)
  }),
  component: HomeOrAgency
});
