import axios from '../axios';

export const postNotification = async (idAgency: number, value: string) => {
  try {
    return await axios.post(`/agence/addNotification/${idAgency}`, { value });
  } catch (e) {
    return e.response;
  }
};

export const deleteNotification = async (idNotification: number) => {
  try {
    return await axios.delete(`/agence/removeNotification/${idNotification}`);
  } catch (e) {
    return e.response;
  }
};

export const getNotifications = async (id: number) => {
  const { data: result } = await axios.get(`/agence/findAllNotification/${id}`);
  return result.data;
};
