import React, { useEffect, useRef } from 'react';
import AddFab from './AddFab';
import { fetchUsers } from '../data/users/users.actions';
import { connect } from '../data/connect';
import UserForm from './UserForm';

interface OwnProps {
  children?: React.ReactNode;
  title?: string;
  idAgency?: number;
}


interface DispatchProps {
  fetchUsers: typeof fetchUsers;
}

const AddUserFab: React.FC<OwnProps & DispatchProps> = ({ fetchUsers}) => {
  let closeModal: any = null;
  let cleanForm: any = null;

  return (
    <AddFab title={'Ajouter un utilisateur'} close={(close: any) => closeModal = close}>
      <UserForm formRef={({ clean }) => { cleanForm = clean; } } onCreate={(idAgency) => {
        fetchUsers(1, true, idAgency);
        closeModal();
        return cleanForm();
      }} />
    </AddFab>
  );
};

export default connect<OwnProps, {}, DispatchProps>({
  mapStateToProps: () => ({}),
  mapDispatchToProps: {
    fetchUsers
  },
  component: React.memo(AddUserFab)
});
