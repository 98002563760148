import React, {useState} from 'react';
import {IonAlert, IonButton, IonIcon, IonItem} from '@ionic/react';
import {trash} from "ionicons/icons";
import {Bounty} from "../models/Bounty";
import {connect} from "../data/connect";

interface OwnProps {
  onDelete?: () => any;
  message: string;
  header: string;
}

type TrashButtonProps = OwnProps;

const TrashButton: React.FC<TrashButtonProps> = ({ onDelete, message, header }) => {
  const [showAlert, setShowAlert] = useState(false);
  return (
    <>
      <IonButton icon-only fill="clear" onClick={() => setShowAlert(true)}>
        <IonIcon slot="icon-only" icon={trash} color={'danger'} />
      </IonButton>
      <IonAlert
        isOpen={showAlert}
        onDidDismiss={() => setShowAlert(false)}
        header={header}
        message={message}
        buttons={[
          'Annuler', {
            text: 'Supprimer',
            handler: () => onDelete && onDelete()
          }
        ]}
      />
    </>
  );
};

export default connect<OwnProps, {}, {}>({
  component: React.memo(TrashButton)
});
