import React, { useEffect, useState } from 'react';
import {
  IonHeader,
  IonToolbar,
  IonContent,
  IonPage,
  IonButtons,
  IonBackButton,
  IonIcon,
  IonText,
  IonList,
  IonItem,
  IonLabel, IonButton, IonToast,
} from '@ionic/react';
import { connect } from '../data/connect';
import { withRouter, RouteComponentProps } from 'react-router';
import * as selectors from '../data/selectors';
import { people, notifications, timer, wallet, bookmarks, download, swap } from 'ionicons/icons';
import './AgencyDetails.scss';
import { Time } from '../components/Time';
import { Agency } from '../models/Agency';
import {
  fetchActivities,
  fetchAgency,
  fetchBounties,
  fetchNotifications, fetchTransferts,
  setAgency
} from '../data/agency/agency.actions';
import { fetchUsers } from '../data/users/users.actions';
import { fetchPointings } from '../data/pointings/pointings.actions';
import AgencyExportModal from '../components/AgencyExportModal';
import AgencyEditModal from '../components/AgencyEditModal';
import { updateAgency } from '../data/api/agency';
import { fetchAgencies } from '../data/agencies/agencies.actions';

type OwnProps = RouteComponentProps;

interface StateProps {
  agency?: Agency;
}

interface DispatchProps {
  setAgency: typeof setAgency;
  fetchAgency: typeof fetchAgency;
  fetchUsers: typeof fetchUsers;
  fetchNotifications: typeof fetchNotifications;
  fetchActivities: typeof fetchActivities;
  fetchBounties: typeof fetchBounties;
  fetchPointings: typeof fetchPointings;
  fetchAgencies: typeof fetchAgencies;
  fetchTransferts: typeof fetchTransferts;
}

type AgencyDetailsProps = OwnProps & StateProps & DispatchProps;

const AgencyDetails: React.FC<AgencyDetailsProps> = ({ agency, fetchAgency, fetchUsers, fetchTransferts, fetchAgencies, fetchNotifications, fetchBounties, fetchPointings, fetchActivities, match }) => {

  const [agencyExport, setAgencyExport] = useState(false);
  const [agencyEdit, setAgencyEdit] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
  // @ts-ignore
  const id = Number(match.params.id);

  const displayToast = (message: string) => {
    setToastMessage(message);
    setShowToast(true);
  };

  useEffect(() => {
    if (isNaN(id)) return;
    fetchAgency(id);
  }, []);

  const editAgency = async (name: string) => {
    if (!agency) return;
    const { data: result, status } = await updateAgency(agency.id, name);
    if (!result || status !== 200) return displayToast('Une erreur est survenue');
    fetchAgency(agency.id);
    fetchAgencies(1, true);
    setAgencyEdit(false);
    return displayToast('L\'agence a bien été mis à jour');
  };

  return (
    <IonPage id="session-detail-page">
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="/tabs/agency"></IonBackButton>
          </IonButtons>
          <IonButtons slot="end">
            <IonButton type="submit" expand="block" color="default" onClick={() => setAgencyEdit(true)}>
              Éditer
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      { !agency &&
        <IonContent>
          <h2>No agency found</h2>
        </IonContent>
      }
      { agency &&
        <IonContent>
          <div className="ion-padding">
            <h1>{agency.name} #{agency.id}</h1>
            <IonText color="medium">
              { agency.createdAt.length && <Time date={agency.createdAt} /> }
              <br />
            </IonText>
          </div>
          <IonList>
            <IonItem routerLink={`/tabs/pointing?agency=${agency.id}`} onClick={() => {
              fetchActivities(agency.id);
              fetchPointings(1, true, agency.id);
            }} button>
              <IonLabel color="primary">Pointage</IonLabel>
              <IonIcon slot="end" color="primary" size="small" icon={bookmarks}/>
            </IonItem>
            <IonItem routerLink={`/tabs/user?agency=${agency.id}`} onClick={() => {
              fetchActivities(agency.id);
              fetchUsers(1, true, agency.id);
            }} button>
              <IonLabel color="primary">Utilisateurs</IonLabel>
              <IonIcon slot="end" color="primary" size="small" icon={people}/>
            </IonItem>
            <IonItem routerLink={`/tabs/bounty?agency=${agency.id}`} onClick={() => {
              fetchActivities(agency.id);
              fetchBounties(agency.id);
            }} button>
              <IonLabel color="primary">Primes</IonLabel>
              <IonIcon slot="end" color="primary" size="small" icon={wallet}/>
            </IonItem>
            <IonItem routerLink={`/tabs/activity?agency=${agency.id}`} onClick={() => {
              fetchActivities(agency.id);
            }} button>
              <IonLabel color="primary">Types d&apos;heures</IonLabel>
              <IonIcon slot="end" color="primary" size="small" icon={timer}/>
            </IonItem>
            <IonItem routerLink={`/tabs/notification?agency=${agency.id}`} onClick={() => {
              fetchActivities(agency.id);
              fetchNotifications(agency.id);
            }} button>
              <IonLabel color="primary">Notifications</IonLabel>
              <IonIcon slot="end" color="primary" size="small" icon={notifications}/>
            </IonItem>
            <IonItem routerLink={`/tabs/transfert?agency=${agency.id}`} onClick={() => {
              fetchTransferts(agency.id);
            }} button>
              <IonLabel color="primary">Zone de transferts</IonLabel>
              <IonIcon slot="end" color="primary" size="small" icon={swap}/>
            </IonItem>
          </IonList>
          <br/>
          <IonList>
            <IonItem onClick={() => {
              setAgencyExport(true);
            }} button>
              <IonLabel color="primary">Exporter</IonLabel>
              <IonIcon slot="end" color="primary" size="small" icon={download}/>
            </IonItem>
          </IonList>
          <AgencyExportModal idAgency={id} isOpen={agencyExport} onClose={() => setAgencyExport(false)}/>
          <AgencyEditModal isOpen={agencyEdit} name={agency.name} onClose={() => setAgencyEdit(false)} onSubmit={(name) => editAgency(name)} />
          <IonToast
            isOpen={showToast}
            onDidDismiss={() => setShowToast(false)}
            message={toastMessage}
            duration={2000}
          />
        </IonContent>
      }
    </IonPage>
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state, OwnProps) => ({
    agency: selectors.getAgency(state, OwnProps) || state.agency,
  }),
  mapDispatchToProps: {
    setAgency,
    fetchAgency,
    fetchUsers,
    fetchNotifications,
    fetchActivities,
    fetchBounties,
    fetchPointings,
    fetchAgencies,
    fetchTransferts
  },
  component: withRouter(AgencyDetails)
});
