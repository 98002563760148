import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonModal, IonRadio,
  IonSearchbar,
  IonTitle,
  IonToolbar
} from '@ionic/react';
import React, { useEffect, useState } from 'react';
import { connect } from '../data/connect';
import { fetchUsers } from '../data/users/users.actions';
import * as selectors from '../data/selectors';
import { User } from '../models/User';
import { Pagination } from '../models/Pagination';

interface UserInputProps {
  onSelect?: (id: number) => any;
  idAgency: number;
  inputRef?: (params: { setValue: any; }) => any;
  required?: boolean;
  idRole?: number;
}

interface StateProps {
  userList: { users: User[]; pagination: Pagination; };
}

interface DispatchProps {
  fetchUsers: typeof fetchUsers;
}

const UserInput: React.FC<UserInputProps & DispatchProps & StateProps> = ({ onSelect, inputRef, userList, fetchUsers, idRole, idAgency, required = true }) => {

  const [disabled, setDisabled] = useState(false);
  const [isModalOpen, showModal] = useState(false);
  const [value, setValue] = useState({ firstname: '', lastname: '' } as any);
  useEffect(() => {
    fetchUsers(1, true, idAgency, idRole);
  }, []);

  const addUsers = async (page: number) => {
    fetchUsers(page, false, idAgency, idRole);
  };

  if (inputRef) inputRef({ setValue });

  return (
    <>
      <IonItem onClick={() => {
        showModal(true);
      }}>
        <IonLabel position="stacked" color="primary">Utilisateur { required && '*'}</IonLabel>
        <IonInput readonly value={`${value.firstname || ''} ${value.lastname || ''}`}/>
      </IonItem>
      <IonModal isOpen={isModalOpen} onDidDismiss={() => fetchUsers(1, true, idAgency, idRole)}>
        <IonHeader>
          <IonToolbar>
            <IonTitle>Utilisateur</IonTitle>
            <IonButtons slot={'start'}>
              <IonButton onClick={() => showModal(false)}>Fermer</IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonSearchbar debounce={300} onIonChange={(e) => fetchUsers(1, true, idAgency, idRole, e.detail.value)} type="text"/>
        <IonContent>
          <IonList>
            {userList.users && userList.users.map((user: any) => <IonItem key={`agency-${user.id}`}>
              <IonLabel>
                <h2>{user.firstname} {user.lastname}</h2>
                <h3>{user.email}</h3>
              </IonLabel>
              <IonRadio slot="start" checked={user.id === value.id} onClick={() => {
                if (onSelect) {
                  onSelect(user.id);
                }
                setValue(user);
                showModal(false);
              }} />
            </IonItem>)}
          </IonList>
          <IonInfiniteScroll disabled={disabled} onIonInfinite={async (e: any) => {
            const currentPage = userList.pagination.currentPage;
            if (userList.pagination.totalPages === currentPage) {
              return setDisabled(true);
            }
            await addUsers(currentPage + 1);
            e.target.complete();
          }}>
            <IonInfiniteScrollContent
              loadingSpinner="bubbles"
              loadingText="Chargement..."
            />
          </IonInfiniteScroll>
        </IonContent>
      </IonModal>
    </>
  );
};

export default connect<UserInputProps, {}, DispatchProps>({
  mapStateToProps: (state) => ({
    userList: selectors.getUsers(state),
  }),
  mapDispatchToProps: {
    fetchUsers
  },
  component: React.memo(UserInput)
});
