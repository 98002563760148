import React, {useRef} from 'react';
import CanvasDraw from 'react-canvas-draw';
import {
  IonButton,
  IonButtons,
  IonCol,
  IonContent, IonGrid,
  IonHeader,
  IonModal,
  IonRow,
  IonTitle,
  IonToolbar
} from '@ionic/react';

interface OwnProps {
  isOpen: boolean;
  onClose?: () => any;
  onSave?: (signature: string) => any;
}

const SignatureModal: React.FC<OwnProps> = ({ isOpen, onClose, onSave }) => {

  const canvasOpts = {
    brushColor: '#0f0f0f',
    brushRadius: 3,
    hideInterface: false,
  };

  const canvasRef: any = useRef(null);

  return (
    <IonModal isOpen={isOpen}>
      <IonHeader translucent>
        <IonToolbar>
          <IonTitle>Signature</IonTitle>
          <IonButtons color={'danger'} slot="end" onClick={() => onClose && onClose()}>
            <IonButton>
              Fermer
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen class="ion-justify-content-center ion-padding-top">
        {/* Key resolve canvas crash */}
        <CanvasDraw key={new Date().getTime().toString()} ref={canvasRef} style={{ margin: 'auto' }} {...canvasOpts}/>
      </IonContent>
      <IonRow>
        <IonCol>
          <IonButton type="submit" expand="block" color="warning" onClick={() => canvasRef.current.clear()}>Effacer</IonButton>
        </IonCol>
        <IonCol>
          <IonButton
            type="submit"
            expand="block"
            onClick={() => onSave && onSave(canvasRef.current.canvasContainer.children[1].toDataURL())}>
            Signer
          </IonButton>
        </IonCol>
      </IonRow>
    </IonModal>
  );
};

export default React.memo(SignatureModal);
