import Storage, { store } from '../../storage';
import axios, { setAuthorization } from '../axios';
import { getRoles } from '../role';
import { getUser as getUserStorage } from '../../storage/user';
import { IS_STORED, TOKEN, USER_AGENCY, USER_INFO, USER_ROLE } from '../../storage/user/keys';
import { Role } from '../../../models/Role';

export const getUserData = async () => {
  const response = await getUserStorage();

  const isLoggedin = await response[0].value === 'true';
  const hasSeenTutorial = await response[1].value === 'true';
  const token = await response[2].value || '';

  const parseRole = (value: string | null): Role => {
    if (!value || !value.length) return { id: 0, name: '' };
    return JSON.parse(value);
  };

  const role = parseRole(await response[3].value);
  const agency = await response[4].value;
  const isStored = await response[5].value === 'true';
  const infos = JSON.parse(await response[6].value || '{}');
  const darkMode = JSON.parse(await response[7].value || 'false');

  const data = {
    isLoggedin,
    hasSeenTutorial,
    role,
    agency: Number(agency),
    infos,
    darkMode
  };

  if (!isLoggedin) {
    return data;
  }

  setAuthorization(token);
  if (isLoggedin && isStored) {
    return data;
  }

  const { data: result } = await axios.get('/user/retrieve');
  if (!result) {
    return data;
  }

  const roles = await getRoles();
  const userRole = roles.find(({ id }: any) => id === Number(result.data.user.id_role));
  await store({
    [USER_ROLE]: JSON.stringify(userRole),
    [USER_INFO]: JSON.stringify(result.data.user),
    [USER_AGENCY]: result.data.user.id_agence,
    [IS_STORED]: true
  });
  return {
    ...data,
    role: userRole,
    infos: result.data.user,
    agency: Number(result.data.user.id_agence)
  };
};

export const postLogin = async (email: string, password: string) => {
  const { data: result } = await axios.post('user/login', {
    email,
    hash: password
  });
  const { token } = result.data;

  if (!result.status && token) {
    throw new Error();
  }
  setAuthorization(token);
  await Storage.set({ key: TOKEN, value: token });
};

export const getUser = async (id: number) => {
  try {
    const { data: result } = await axios.get(`/user/retrieve/${id}`);
    return result;
  } catch (e) {
    return e.response;
  }
};

export const getUsers = async (page?: number, agency?: number, idRole?: number, search?: string) => {
  const { data: result } = await axios.get('user/findAll', {
    params: {
      page: page || 1,
      limit: 15,
      // eslint-disable-next-line @typescript-eslint/camelcase
      ...agency ? { id_agence: agency } : {},
      // eslint-disable-next-line @typescript-eslint/camelcase
      ...idRole ? { id_role: idRole } : {},
      ...search && search.length ? { search } : {},
    }
  });
  if (!result.data.users || result.data.users && !result.data.users.length) {
    return result;
  }
  const roles = await getRoles();
  const getUserRole = (idRole: string) => roles.find(({ id }: any) => id === Number(idRole));
  return {
    ...result.data,
    users: result.data.users.map((u: any) => ({
      ...u,
      role: getUserRole(u.id_role)
    }))
  };
};

export const postUser = async (data: any) => {
  const { data: result } = await axios.post('/user/register', data);
  if (!result.status) {
    throw new Error(result.data.message);
  }
  return result.data;
};

export const updateUser = async (id: number, data: any) => {
  try {
    const { data: result } = await axios.put(`/user/update/${id}`, data);
    return result;
  } catch (e) {
    return e.response;
  }
};

export const deleteUser = async (id: number) => {
  try {
    const { data: result } = await axios.delete(`/user/remove/${id}`);
    return result;
  } catch (e) {
    return e.response;
  }
};

export const postUsers = async (formData: FormData) => {
  try {
    const { data: result } = await axios.post('/user/import', formData, {
      headers: {
        'Content-Type': 'multiplart/form-data'
      }
    });
    return result;
  } catch (e) {
    return e.response;
  }
};
