import { ActionType } from '../../util/types';
import { Agency } from '../../models/Agency';
import { Notification } from '../../models/Notification';
import { getNotifications } from '../api/notification';
import { getUserData } from '../api/user';
import { Activity } from '../../models/Activity';
import { getActivities } from '../api/activity';
import { Bounty } from '../../models/Bounty';
import { getBounties } from '../api/bounty';
import { getAgency } from '../api/agency';
import { getTransferts } from '../api/transfert';
import { Transfert } from '../../models/Transfert';

export const setLoading = (isLoading: boolean) => ({
  type: 'set-agency-loading',
  isLoading
} as const);

export const setAgency = (agency: Agency) => ({
  type: 'set-agency',
  agency
} as const);

export const setNotification = (notifications: Notification[]) => ({
  type: 'set-notification',
  notifications
} as const);

export const setActivities = (activities: Activity[]) => ({
  type: 'set-activity',
  activities
} as const);

export const setBounties = (bounties: Bounty[]) => ({
  type: 'set-bounty',
  bounties
} as const);

export const setTransferts = (transferts: Transfert[]) => ({
  type: 'set-transfert',
  transferts
} as const);

export const fetchAgency = (id?: number) => async (dispatch: React.Dispatch<any>) => {
  const idAgency = id || (await getUserData()).agency;
  dispatch(setLoading(true));
  const result = await getAgency(idAgency);
  if (!result.agence) return;
  dispatch(setAgency(result.agence));
  dispatch(setLoading(false));
};

export const fetchNotifications = (id?: number) => async (dispatch: React.Dispatch<any>) => {
  const idAgency = id || (await getUserData()).agency;
  // eslint-disable-next-line no-shadow
  const { notifications } = await getNotifications(idAgency);
  dispatch(setNotification(notifications));
};

export const fetchActivities = (id?: number, isDeleted?: boolean) => async (dispatch: React.Dispatch<any>) => {
  const idAgency = id || (await getUserData()).agency;
  const { activities } = await getActivities(idAgency, isDeleted);
  dispatch(setActivities(activities));
};

export const fetchTransferts = (id?: number, isDeleted?: boolean) => async (dispatch: React.Dispatch<any>) => {
  const idAgency = id || (await getUserData()).agency;
  const { transferts } = await getTransferts(idAgency, isDeleted);
  dispatch(setTransferts(transferts));
};

export const fetchBounties = (id?: number) => async (dispatch: React.Dispatch<any>) => {
  const idAgency = id || (await getUserData()).agency;
  const bounties = await getBounties(idAgency);
  dispatch(setBounties(bounties));
};

export type AgencyActions =
  | ActionType<typeof setLoading>
  | ActionType<typeof setAgency>
  | ActionType<typeof setNotification>
  | ActionType<typeof setActivities>
  | ActionType<typeof setBounties>
  | ActionType<typeof setTransferts>
