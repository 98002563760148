import React, { useRef, useState } from 'react';
import {
  IonItemSliding,
  IonItemOptions,
  IonItemOption,
  IonIcon, IonLabel, IonNote, IonItem, IonButton
} from '@ionic/react';
import { PointingState } from '../util/types';
import {paperPlane, copy, closeCircle, alert, checkmarkCircle, trash} from 'ionicons/icons';
import { Pointing } from '../models/Pointing';
import { format, parseISO } from 'date-fns';

interface PointingListItemProps {
  state: PointingState;
  value: Pointing;
  idAgency: number;
  onCopy?: (id: string | number) => any;
  onShare?: (id: string | number) => any;
}

const STATE_ICON = [
  { icon: alert, color: 'warning' },
  { icon: checkmarkCircle, color: 'success' },
  { icon: closeCircle, color: 'danger' }
];

const PointingListItem: React.FC<PointingListItemProps> = ({ state, idAgency, value, onCopy, onShare }) => {
  const ionItemSlidingRef = useRef<HTMLIonItemSlidingElement>(null);
  return (
    <IonItemSliding ref={ionItemSlidingRef} class={'track-'}>
      <IonItem routerLink={`/tabs/pointing/${value.id}${idAgency > 0 ? `?agency=${idAgency}` : ''}`}>
        <IonNote style={{
          marginTop: 20,
          width: 25
        }}
        slot="start" color="dark">
          <IonIcon icon={STATE_ICON[state - 1].icon} color={STATE_ICON[state - 1].color} size="large"/>
        </IonNote>
        <IonLabel>
          <h2><b>#{value.id}</b> - {value.constructionSitename || value.constructionSiteNumber}</h2>
          <h3> {format(parseISO(value.date), 'dd/MM/yyyy')} - De
            &nbsp;{`${(value.startDate || '::').split(':')[0]}h${(value.startDate || '::').split(':')[1]}`} à
            &nbsp;{`${(value.endDate || '::').split(':')[0]}h${(value.endDate || '::').split(':')[1]}`}
          </h3>
          <p>&nbsp;soit {value.elapsedTime}</p>
          <p>&nbsp;de&nbsp;{value.user!.firstname.replace(/^\w/, c => c.toUpperCase())} {value.user!.lastname}</p>
        </IonLabel>
        {/* eslint-disable-next-line multiline-ternary */}
        <IonNote style={{
          display: 'grid',
          paddingTop: 14,
          paddingLeft: 0,
          marginLeft: 0
        }} slot="end">
          <IonButton icon-only size="large" fill="clear" onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            if (onCopy) onCopy(value.id || 0);
          }}>
            <IonIcon slot="icon-only" icon={copy} color={'danger'} />
          </IonButton>
          { state < 3  && <IonButton size="large" icon-only fill="clear" onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            if (onShare) onShare(value.id || 0);
          }}>
            <IonIcon slot="icon-only" icon={paperPlane} color={state === 2 ? 'success' : 'warning'} />
          </IonButton> }
        </IonNote>
      </IonItem>
    </IonItemSliding>
  );
};

export default React.memo(PointingListItem);
