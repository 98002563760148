import {
  IonButton,
  IonList,
  IonItem,
  IonLabel,
  IonInput,
  IonRow,
  IonCol,
  IonText,
  IonSelectOption,
  IonSelect,
  IonListHeader, IonToast
} from '@ionic/react';
import React, { FormEvent, useEffect, useState } from 'react';
import { connect } from '../data/connect';
import { getUserData, postUser, updateUser } from '../data/api/user';
import { getRoles } from '../data/api/role';
import AgencyInput from './AgencyInput';

import './UserForm.scss';
import { Role } from '../models/Role';
import {validateEmail, validatePhone} from "../util/validator";

interface UserFormProps {
  children?: React.ReactNode;
  title?: string;
  onCreate?: (idAgency: number) => any;
  onUpdate?: () => any;
  formRef?: (formAction: { form: any; clean: any; setForm: any; }) => any;
  update?: boolean;
}


interface DispatchProps {}

const UserForm: React.FC<UserFormProps & DispatchProps> = ({ onCreate, onUpdate, formRef, update = false }) => {

  const initForm = {
    id: 0,
    firstname: '',
    lastname: '',
    email: '',
    phone: '',
    hash: '',
    // eslint-disable-next-line @typescript-eslint/camelcase
    id_agence: 0,
    // eslint-disable-next-line @typescript-eslint/camelcase
    id_role: 0,
    carName: '',
    carNumber: '',
    machineName: '',
    machineNumber: ''
  };
  const [form, setForm] = useState(initForm);
  const [roles, setRoles] = useState([]);
  const [userRole, setUserRole] = useState({ name: '' } as Role);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');

  const afterCreate = () => {
    setForm(initForm);
  };

  if (formRef) {
    formRef({ form, clean: afterCreate, setForm });
  }

  useEffect(() => {
    if (update) return;
    getRoles().then(async (roles) => {
      const { role, agency } = await getUserData();
      const isAdmin = role.id === 2;

      setUserRole(role);
      setRoles(isAdmin ? roles.filter(({ id }: Role) => id !== 1) : roles);
      if (!isAdmin) return;
      setForm({
        ...form,
        // eslint-disable-next-line @typescript-eslint/camelcase
        id_agence: agency
      });
    });
  }, []);

  const [formSubmitted, setFormSubmitted] = useState(false);
  const [formError, setFormError] = useState({
    firstname: false,
    lastname: false,
    email: false,
    phone: false,
    hash: false,
    role: false,
    agency: false,
    // Vehicle errors
    carName: false,
    carNumber: false,
    machineName: false,
    machineNumber: false
  });

  const checkForm = () => {
    let hasError = false;
    const tempFormError = {} as any;
    Object.entries(form).forEach(([key, value]: any) => {
      const ignored = [
        'carName',
        'carNumber',
        'machineName',
        'machineNumber'
      ];
      const isIgnored = (name: string) => ignored.some(e => e === name);
      if (typeof value === 'number') {
        return;
      }
      if (!isIgnored(key) && !value) {
        tempFormError[key] = true;
        hasError = true;
      }
    });

    const checkIds = () => {
      // if form user is super admin, doesn't check agency
      if (form.id_role === 1) { return true; }
      return !(!form.id_role || !form.id_agence);
    };

    if (hasError || !checkIds()) {
      setFormError(tempFormError);
      return false;
    }

    if (!validateEmail(form.email)) {
      setFormError({
        ...formError,
        email: true
      });
      return false;
    }

    if (!validatePhone(form.phone)) {
      setFormError({
        ...formError,
        phone: true
      });
      return false;
    }
    return true;
  };

  const createUser = async (e: FormEvent) => {
    e.preventDefault();
    setFormSubmitted(true);

    if (!checkForm()) {
      return;
    }

    const result = await postUser({
      ...form,
      // eslint-disable-next-line @typescript-eslint/camelcase
      ...form.id_role === 1 ? { id_agence: undefined } : {}
    });
    if (!result.user) { return; }
    if (onCreate) {
      onCreate(form.id_agence);
      afterCreate();
    }
  };

  const updateUserData = async (e: FormEvent) => {
    e.preventDefault();
    if (!form.id) return;
    const result = await updateUser(form.id, {
      ...form
    });
    if (result.status && (result.status !== true || typeof result.status === 'number' && result.status !== 200)) {
      setToastMessage('Une erreur est survenue');
      return setShowToast(true);
    }
    setToastMessage(result.alert.message);
    setShowToast(true);
    if (onUpdate) onUpdate();
  };

  const isDriver = form.id_role === 3;
  const isSuperAdmin = form.id_role === 1;

  return (
    <form
      noValidate
      onSubmit={(e) => {
        e.preventDefault();
        return !update ? createUser(e) : updateUserData(e);
      }}
      style={{ padding: '16px', width: 'inherit' }}>
      <IonList>
        <IonListHeader>
          <IonLabel>
            <h2>Informations utilisateur</h2>
          </IonLabel>
        </IonListHeader>
        { !update && <IonItem>
          <IonLabel position="stacked" color="primary">Role *</IonLabel>
          {/* eslint-disable-next-line @typescript-eslint/camelcase */}
          <IonSelect value={Number(form.id_role)} onIonChange={(e: any) => setForm({ ...form, id_role: e.detail.value! })} cancelText="Annuler" okText="Valider">
            {roles.map((r: any) => <IonSelectOption key={`role-${r.id}-${r.name}`} value={r.id}>{r.name}</IonSelectOption>)}
          </IonSelect>
          {formSubmitted && formError.role && <IonText color="danger">
            <p className="error-message">
              {/* eslint-disable-next-line react/no-unescaped-entities */}
                  Le role est requis
            </p>
          </IonText>}
        </IonItem>
        }
        <IonItem>
          <IonLabel position="stacked" color="primary">Prénom *</IonLabel>
          <IonInput type="text" name={'firstname'} spellCheck={false} required value={form.firstname} onIonChange={(e) => setForm({ ...form, firstname: e.detail.value! })}/>
        </IonItem>
        {formSubmitted && formError.firstname && <IonText color="danger">
          <p className="error-message">
                Le prénom est requis
          </p>
        </IonText>}
        <IonItem>
          <IonLabel position="stacked" color="primary">Nom *</IonLabel>
          <IonInput type="text" name={'lastname'} spellCheck={false} required value={form.lastname} onIonChange={(e) => setForm({ ...form, lastname: e.detail.value! })}/>
        </IonItem>
        {formSubmitted && formError.lastname && <IonText color="danger">
          <p className="error-message">
                Le nom est requis
          </p>
        </IonText>}
        <IonItem>
          <IonLabel position="stacked" color="primary">Email *</IonLabel>
          <IonInput type={'email'} name={'email'} spellCheck={false} required value={form.email} onIonChange={(e) => setForm({ ...form, email: e.detail.value! })}/>
        </IonItem>
        {formSubmitted && formError.email && <IonText color="danger">
          <p className="error-message">
            {/* eslint-disable-next-line react/no-unescaped-entities */}
                L'email est requis
          </p>
        </IonText>}
        <IonItem>
          <IonLabel position="stacked" color="primary">Téléphone *</IonLabel>
          <IonInput type={'tel'} name={'phone'} spellCheck={false} required value={form.phone} onIonChange={(e) => setForm({ ...form, phone: e.detail.value! })}/>
        </IonItem>
        {formSubmitted && formError.phone && <IonText color="danger">
          <p className="error-message">
            {/* eslint-disable-next-line react/no-unescaped-entities */}
                Le numéro de téléphone est requis
          </p>
        </IonText>}
        <IonItem>
          <IonLabel position="stacked" color="primary">Mot de passe *</IonLabel>
          <IonInput type={'password'} name={'password'} spellCheck={false} required value={form.hash} onIonChange={(e) => setForm({ ...form, hash: e.detail.value! })}/>
        </IonItem>
        {formSubmitted && formError.hash && <IonText color="danger">
          <IonLabel className="error-message">
                Le mot de passe est requis
          </IonLabel>
        </IonText>}
        { isSuperAdmin || userRole.name === 'Super Administrateur' &&
          <>
            {/* eslint-disable-next-line @typescript-eslint/camelcase */}
            <AgencyInput onSelect={(value) => setForm({ ...form, id_agence: value })}/>
            {formSubmitted && formError.agency && <IonText color="danger">
              <p className="error-message">
                {/* eslint-disable-next-line react/no-unescaped-entities */}
                    L'agence est requise
              </p>
            </IonText>}
          </>
        }
        { isDriver &&
            <>
              <IonListHeader>
                <IonLabel>
                  <h2>Informations véhicule</h2>
                </IonLabel>
              </IonListHeader>
              <IonItem>
                <IonLabel position="stacked" color="primary">Nom du véhicule</IonLabel>
                <IonInput type="text" name={'carName'} spellCheck={false} value={form.carName} onIonChange={(e) => setForm({ ...form, carName: e.detail.value! })}/>
              </IonItem>
              <IonItem>
                <IonLabel position="stacked" color="primary">Numéro du véhicule</IonLabel>
                <IonInput type="text" name={'carNumber'} spellCheck={false} value={form.carNumber} onIonChange={(e) => setForm({ ...form, carNumber: e.detail.value! })}/>
              </IonItem>
              <IonItem>
                <IonLabel position="stacked" color="primary">Nom de l&apos;engin</IonLabel>
                <IonInput type="text" name={'machineName'} spellCheck={false} value={form.machineName} onIonChange={(e) => setForm({ ...form, machineName: e.detail.value! })}/>
              </IonItem>
              <IonItem>
                <IonLabel position="stacked" color="primary">Numéro de l&apos;engin</IonLabel>
                <IonInput type="text" name={'machineNumber'} spellCheck={false} value={form.machineNumber} onIonChange={(e) => setForm({ ...form, machineNumber: e.detail.value! })}/>
              </IonItem>
            </>}
      </IonList>
      <IonRow>
        <IonCol>
          <IonButton type="submit" expand="block">{ update ? 'Enregistrer' : 'Ajouter'}</IonButton>
        </IonCol>
      </IonRow>
      <IonToast
        isOpen={showToast}
        message={toastMessage}
        duration={2000}
        onDidDismiss={() => setShowToast(false)}
      />
    </form>
  );
};

export default connect<UserFormProps, {}, DispatchProps>({
  mapStateToProps: () => ({}),
  component: React.memo(UserForm)
});
