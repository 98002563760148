import React  from 'react';
import { IonRouterOutlet } from '@ionic/react';
import { Route, Redirect } from 'react-router';
import PointingPage from './PointingPage';
import PointingDetails from './PointingDetails';
import AgencyPage from './AgencyPage';
import AgencyDetails from './AgencyDetails';
import UserPage from './UserPage';
import UserDetails from './UserDetails';
import NotificationPage from './NotificationPage';
import BountyPage from './BountyPage';
import ActivityPage from './ActivityPage';
import TransfertPage from "./TransfertPage";

interface MainTabsProps { }


const MainTabs: React.FC<MainTabsProps> = () => {

  return (
    <IonRouterOutlet>
      <Redirect exact path="/tabs" to="/tabs/pointing" />
      {/*
          Using the render method prop cuts down the number of renders your components will have due to route changes.
          Use the component prop when your component depends on the RouterComponentProps passed in automatically.
        */}
      <Route path="/tabs/pointing" render={() => <PointingPage />} exact/>
      <Route path="/tabs/pointing/:id" component={PointingDetails} />
      <Route path="/tabs/agency" render={() => <AgencyPage />} exact/>
      <Route path="/tabs/agency/:id" component={AgencyDetails}/>
      <Route path="/tabs/notification" render={() => <NotificationPage/>} exact />
      <Route path="/tabs/notification/:id"/>
      <Route path="/tabs/activity" render={() => <ActivityPage/>} exact />
      <Route path="/tabs/transfert" render={() => <TransfertPage/>} exact />
      <Route path="/tabs/bounty" render={() => <BountyPage/>}/>
      <Route path="/tabs/user" render={() => <UserPage/>}/>
      <Route path="/tabs/user/:id" component={UserDetails}/>
    </IonRouterOutlet>
  );
};

export default MainTabs;
