import {
  IonItem,
  IonLabel, IonNote,
  IonSelect,
  IonSelectOption,
} from '@ionic/react';
import React, { useEffect, useState } from 'react';
import { connect } from '../data/connect';
import './ScheduleForm.scss';
import { Bounty } from '../models/Bounty';

interface BountyListFormProps {
  value: Bounty[];
  onChange: (data: any) => any;
  readonly: boolean;
}

interface StateProps {}

interface DispatchProps {}

const BountyListForm: React.FC<BountyListFormProps & DispatchProps & StateProps> = ({ value, onChange, readonly }) => {

  const form = value || [];
  const setForm = (data: any) => onChange(data);

  const setValue = (key: number, data: Partial<Bounty>) => {
    const newForm = form.map((a) => ({
      ...a,
      ...a.id === key ? data : {}
    }));
    setForm(newForm);
  };

  return (
    <>
      {!form.length && <p className="ion-padding-start">Aucune primes</p>}
      {form.map((bounty) => <IonItem key={`bounty-${bounty.name}`}>
        <IonLabel slot="start">{bounty.name}</IonLabel>
        { readonly && <IonLabel slot="end">{bounty.bountyValues.find(bountyValue => bountyValue.id === bounty.idBountyValue)!.value}</IonLabel>}
        { !readonly && <IonSelect
          slot="end"
          value={(() => {
            const defaultValue = bounty.bountyValues.find(bountyValue => bountyValue.isDefault);
            if (bounty.idBountyValue) return bounty.idBountyValue;
            if (defaultValue && defaultValue.id) return defaultValue.id;
            return 0;
          })()}
          onIonChange={(e) => setValue(bounty.id, { ...bounty, idBountyValue: e.detail.value })}
        >
          {bounty.bountyValues.map(bountyValue => <IonSelectOption key={`bounty-value-${bountyValue.id}`} value={bountyValue.id}>
            {bountyValue.value}
          </IonSelectOption>)}
        </IonSelect>}
      </IonItem>)}
    </>
  );
};

export default connect<BountyListFormProps, StateProps, DispatchProps>({
  mapStateToProps: () => ({}),
  component: React.memo(BountyListForm)
});
