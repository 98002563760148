import React, { useState } from 'react';
import AddFab from './AddFab';
import { fetchPointings } from '../data/pointings/pointings.actions';
import { connect } from '../data/connect';
import PointingForm from './PointingForm';
import { RouteComponentProps, withRouter } from 'react-router';
import { IonLoading } from '@ionic/react';
import { getCurrentUser } from '../data/selectors';
import { PointingState } from '../util/types';

interface OwnProps {
  children?: React.ReactNode;
  title?: string;
  formRef?: (params: { clean: any; setForm: any; form: any; }) => any;
  isOpen?: boolean;
  idAgency: number;
  onClose?: () => any;
}


interface DispatchProps {
  fetchPointings: typeof fetchPointings;
}

interface StateProps {
  user: any;
  pointingState: PointingState;
}

const AddPointingFab: React.FC<OwnProps & DispatchProps & StateProps & RouteComponentProps> = ({ history, user, idAgency, formRef, isOpen, onClose, pointingState, fetchPointings }) => {
  const [showLoading, setShowLoading] = useState({
    isLoading: false,
    result: {} as any,
  });
  let closeModal: any = null;
  let cleanForm: any = null;
  return (
    <AddFab
      isOpen={isOpen}
      title={'Ajouter un pointage'}
      onClose={() => {
        cleanForm();
        return onClose && onClose();
      }}
      close={(close: any) => closeModal = close}>
      <PointingForm
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore
        idAgency={idAgency}
        isAdmin={user && user.role && user.role.id !== 3}
        formRef={({ clean, setForm, form }) => {
          cleanForm = clean;
          if (formRef) formRef({ clean, setForm, form });
        }}
        onCreate={(result, toSigned) => {
          fetchPointings(1, true, idAgency, pointingState);
          closeModal();
          cleanForm();
          if (onClose) onClose();
          if (toSigned) {
            return setShowLoading({
              isLoading: true,
              result
            });
          }
        }} />
      <IonLoading
        isOpen={showLoading.isLoading}
        message={'chargement'}
        duration={1500}
        spinner="crescent"
        onDidDismiss={() => {
          // fetchPointings();
          history.push(`/tabs/pointing/${showLoading.result.id}?token=${showLoading.result.token}`, { direction: 'none' });
          setShowLoading({
            isLoading: false,
            result: {}
          });
        }}
      />
    </AddFab>
  );
};

export default connect<OwnProps, {}, DispatchProps>({
  mapStateToProps: (state) => ({
    user: getCurrentUser(state),
    pointingState: state.pointings.state
  }),
  mapDispatchToProps: {
    fetchPointings
  },
  component: withRouter(AddPointingFab)
});
