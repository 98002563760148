import React, { useState, useRef, useEffect } from 'react';
import {
  IonToolbar,
  IonContent,
  IonPage,
  IonButtons,
  IonMenuButton,
  IonRefresher,
  IonRefresherContent,
  IonToast,
  IonHeader, IonBackButton, IonTitle
} from '@ionic/react';
import { connect } from '../data/connect';
import BountyList from '../components/BountyList';
import { fetchBounties } from '../data/agency/agency.actions';
import { Bounty } from '../models/Bounty';
import { getBounties, getUserAgency } from '../data/selectors';
import { RouteComponentProps, withRouter } from 'react-router';
import {getAgency} from "../data/api/agency";

interface OwnProps {}

interface StateProps {
  bounties: Bounty[];
  agency: number;
  role: any;
}

interface DispatchProps {
  fetchBounties: typeof fetchBounties;
}

type BountyPageProps = OwnProps & StateProps & DispatchProps & RouteComponentProps;

const BountyPage: React.FC<BountyPageProps> = ({ fetchBounties, bounties, location, agency, role }) => {
  const [segment, setSegment] = useState<'all' | 'favorites'>('all');
  const [showFilterModal, setShowFilterModal] = useState(false);
  const ionRefresherRef = useRef<HTMLIonRefresherElement>(null);
  const [showCompleteToast, setShowCompleteToast] = useState(false);
  const [agencyName, setAgencyName] = useState('');
  const idAgency = Number(new URLSearchParams(location.search).get('agency')) || 0;

  useEffect(() => {
    if (idAgency) {
      getAgency(idAgency).then(({ agence }) => {
        setAgencyName(agence.name || '');
      });
    }
    fetchBounties(idAgency);
  }, []);

  const doRefresh = () => {
    fetchBounties(idAgency);
    setTimeout(() => {
      ionRefresherRef.current!.complete();
      setShowCompleteToast(true);
    }, 1000);
  };

  return (
    <IonPage id="pointing-page">
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            { role && role.id === 1 ?
              <IonBackButton defaultHref={`/tabs/agency/${idAgency}`}/> :
              <IonMenuButton />
            }
          </IonButtons>
          <IonTitle>
            Primes { agencyName ? `de ${agencyName}` : ''}
          </IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent>
        <IonRefresher slot="fixed" ref={ionRefresherRef} onIonRefresh={doRefresh}>
          <IonRefresherContent />
        </IonRefresher>
        <IonToast
          isOpen={showCompleteToast}
          message="Contenu rafraichi"
          duration={2000}
          onDidDismiss={() => setShowCompleteToast(false)}
        />
        <BountyList idAgency={idAgency || agency} bountyList={bounties} onDelete={() => fetchBounties()}/>
      </IonContent>
    </IonPage>
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    agency: getUserAgency(state),
    bounties: getBounties(state),
    role: state.user.role
  }),
  mapDispatchToProps: {
    fetchBounties
  },
  component: withRouter(BountyPage)
});
