import { createSelector } from 'reselect';
import { AppState } from './state';

const getIdParam = (_state: AppState, props: any) => {
  const stringParam = props.match.params['id'];
  return parseInt(stringParam, 10);
};

export const getAgencies = (state: AppState) => ({ ...state.agencies.data });
export const getUsers = (state: AppState) => ({ ...state.users.data });

export const getRoles = (state: AppState) => [...state.users.roles];

export const getAgency = createSelector(
  getAgencies, getIdParam,
  ({ agencies }, id) => {
    return agencies.find((a: any) => a.id === id);
  }
);

export const getUser = createSelector(
  getUsers, getIdParam,
  ({ users }, id) => {
    return users.find((a: any) => a.id === id);
  }
);

export const getCurrentUser = (state: AppState) => ({ ...state.user });

export const getUserAgency = (state: AppState) => state.user.agency;

export const getNotifications = (state: AppState) => [...state.agency.notifications];

export const getActivities = (state: AppState) => [...state.agency.activities];

export const getBounties = (state: AppState) => [...state.agency.bounties];

export const getTransferts = (state: AppState) => [...state.agency.transferts];

export const getPointings = (state: AppState) => ({ ...state.pointings.data });

export const getPointingState = (state: AppState) => state.pointings.state;
