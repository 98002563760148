import React, { useState, useRef, useEffect } from 'react';
import {
  IonToolbar,
  IonContent,
  IonPage,
  IonButtons,
  IonMenuButton,
  IonRefresher,
  IonRefresherContent,
  IonToast,
  IonHeader,
  IonBackButton, IonTitle
} from '@ionic/react';
import { connect } from '../data/connect';
// import './TransfertPage.scss';
import TransfertList from '../components/TransfertList';
import { getTransferts, getUserAgency } from '../data/selectors';
import { fetchTransferts } from '../data/agency/agency.actions';
import { Transfert } from '../models/Transfert';
import { RouteComponentProps, withRouter } from 'react-router';
import { getAgency } from '../data/api/agency';

interface OwnProps { }

interface StateProps {
  transferts: Transfert[];
  agency: number;
  role: any;
}

interface DispatchProps {
  fetchTransferts: typeof fetchTransferts;
}

type TransfertPageProps = OwnProps & StateProps & DispatchProps & RouteComponentProps;

const TransfertPage: React.FC<TransfertPageProps> = ({ fetchTransferts, transferts, agency, location, role }) => {
  const ionRefresherRef = useRef<HTMLIonRefresherElement>(null);
  const [showCompleteToast, setShowCompleteToast] = useState(false);
  const [agencyName, setAgencyName] = useState('');
  const idAgency = Number(new URLSearchParams(location.search).get('agency')) || 0;

  useEffect(() => {
    if (idAgency) {
      getAgency(idAgency).then(({ agence }) => {
        setAgencyName(agence.name || '');
      });
    }
    fetchTransferts(idAgency);
  }, []);

  const doRefresh = () => {
    fetchTransferts(idAgency);
    setTimeout(() => {
      ionRefresherRef.current!.complete();
      setShowCompleteToast(true);
    }, 1000);
  };

  return (
    <IonPage id="pointing-page">
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            { role && role.id === 1 ?
              <IonBackButton defaultHref={`/tabs/agency/${idAgency}`}/> :
              <IonMenuButton />
            }
          </IonButtons>
          <IonTitle>
            Zone de transfert { agencyName ? `de ${agencyName}` : ''}
          </IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent>
        <IonRefresher slot="fixed" ref={ionRefresherRef} onIonRefresh={doRefresh}>
          <IonRefresherContent />
        </IonRefresher>
        <IonToast
          isOpen={showCompleteToast}
          message="Contenu rafraichi"
          duration={2000}
          onDidDismiss={() => setShowCompleteToast(false)}
        />
        <TransfertList idAgency={idAgency || agency} transfertList={transferts}/>
      </IonContent>
    </IonPage>
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    agency: getUserAgency(state),
    transferts: getTransferts(state),
    role: state.user.role
  }),
  mapDispatchToProps: {
    fetchTransferts
  },
  component: withRouter(TransfertPage)
});
