import React, { useState } from 'react';
import {
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonDatetime,
  IonGrid,
  IonHeader,
  IonItem,
  IonList,
  IonModal,
  IonRow,
  IonTitle,
  IonToolbar
} from '@ionic/react';
import UserInput from './UserInput';
import { url } from '../config/config.json';

interface OwnProps {
  isOpen?: boolean;
  onClose?: () => any;
  idAgency?: number;
  onSubmit?: () => any;
}

const AgencyExportModal: React.FC<OwnProps> = ({ isOpen = false, onClose, onSubmit, idAgency = 0 }) => {

  const [form, setForm] = useState({
    startDate: '',
    endDate: new Date().toISOString(),
    user: 0
  });

  const exportPointings = (e: any) => {
    e.preventDefault();
    const link = document.getElementById('download-export');
    if (!link) return;
    link.click();
  };

  return (
    <IonModal isOpen={isOpen} onDidDismiss={() => onClose && onClose()}>
      <IonHeader translucent>
        <IonToolbar>
          <IonTitle>Exporter les pointages</IonTitle>
          <IonButtons color={'danger'} slot="end" onClick={() => onClose && onClose()}>
            <IonButton>
              Fermer
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <form noValidate onSubmit={exportPointings}>
          <IonList>
            <IonItem>
              <IonGrid>
                <IonRow class="ion-text-center">
                  <IonCol offset="3" size="3">
                    <b>Début</b>
                  </IonCol>
                  <IonCol size="3">
                    <b>Fin</b>
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol size="6" class="ion-text-end">
                    <IonDatetime
                      cancelText="annuler"
                      doneText="confirmer"
                      displayFormat="DD/MM/YYYY"
                      value={form.startDate}
                      placeholder="10/06/2020"
                      onIonChange={(e) => setForm({ ...form, startDate: e.detail.value! })}
                    />
                  </IonCol>
                  <IonCol size="6" class="ion-text-start">
                    <IonDatetime
                      cancelText="annuler"
                      doneText="confirmer"
                      displayFormat="DD/MM/YYYY"
                      value={form.endDate}
                      onIonChange={(e) => setForm({ ...form, endDate: e.detail.value! })}/>
                  </IonCol>
                </IonRow>
              </IonGrid>
            </IonItem>
            <UserInput idRole={3} required={false} idAgency={idAgency} onSelect={(user) => setForm({ ...form, user })}/>
            <IonRow>
              <IonCol>
                <IonButton type="submit" expand="block">Exporter</IonButton>
                <a
                  target="_blank" rel="noopener noreferrer"
                  download
                  hidden
                  id="download-export"
                  href={`https://eurovia-pointage.demosoft.fr/index.php/api/pointing/export/${idAgency}?startDate=${form.startDate}&endDate=${form.endDate}&user=${form.user}`}/>
              </IonCol>
            </IonRow>
          </IonList>
        </form>
      </IonContent>
    </IonModal>
  );
};

export default React.memo(AgencyExportModal);
