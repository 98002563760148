import React, { useEffect } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { IonApp, IonRouterOutlet, IonSplitPane } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';

import Menu from './components/Menu';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import MainTabs from './pages/MainTabs';
import { connect } from './data/connect';
import { AppContextProvider } from './data/AppContext';
import { setIsLoggedIn, setUsername, loadUserData } from './data/user/user.actions';
import Account from './pages/Account';
import Login from './pages/Login';
import Signup from './pages/Signup';
import HomeOrAgency from './components/HomeOrAgency';
import { clearUser } from './data/storage/user';
import { loadRoles } from './data/users/users.actions';
import ShareablePointing from './pages/ShareablePointing';

import { AppUpdate } from '@ionic-native/app-update';


const App: React.FC = () => {
  return (
    <AppContextProvider>
      <IonicAppConnected />
    </AppContextProvider>
  );
};

interface StateProps {
  darkMode: boolean;
  isLogged: boolean;
}

interface DispatchProps {
  loadUserData: typeof loadUserData;
  setIsLoggedIn: typeof setIsLoggedIn;
  setUsername: typeof setUsername;
  loadRoles: typeof loadRoles;
}

interface IonicAppProps extends StateProps, DispatchProps { }

const IonicApp: React.FC<IonicAppProps> = ({ darkMode, isLogged, loadRoles, loadUserData }) => {

  const appUpdate = AppUpdate;
  const updateUrl = 'https://eurovia-pointage.demosoft.fr/update.xml';
  useEffect(() => {
    loadUserData();
    loadRoles();
    appUpdate.checkAppUpdate(updateUrl)
      .then(() => { console.log('update available'); })
      .catch(() => { console.log('error during update checking'); });
  }, []);

  const pointings = [{}];

  return (
    pointings.length === 0 ?
      <div></div>
      :
      <IonApp className={`${darkMode ? 'dark-theme' : ''}`}>
        <IonReactRouter>
          <IonSplitPane contentId="main">
            { isLogged && <Menu />}
            <IonRouterOutlet id="main">
              <Route path="/" component={HomeOrAgency} exact />
              <Route path="/tabs" render={ props => isLogged ? <MainTabs {...props}/> : <Redirect to={'/login'}/>} />
              <Route path="/share/:id" component={ShareablePointing} />
              <Route path="/account" component={Account} />
              <Route path="/login" component={Login} />
              <Route path="/signup" component={Signup} />
              <Route path="/logout" render={async () => {
                // TODO: Refacto logout
                await clearUser();
                window.location.href = '/login';
                return null;
              }} exact />
            </IonRouterOutlet>
          </IonSplitPane>
        </IonReactRouter>
      </IonApp>

  );
};

export default App;

const IonicAppConnected = connect<{}, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    darkMode: state.user.darkMode,
    isLogged: state.user.isLoggedin
  }),
  mapDispatchToProps: { loadUserData, setIsLoggedIn, setUsername, loadRoles },
  component: IonicApp
});
