import { Plugins } from '@capacitor/core';

const { Storage } = Plugins;

export const store = (data: any) => {
  const toSave = Object.entries(data).map(([key, value]: any) => Storage.set({ key, value }));
  return Promise.all(toSave);
};

export default Storage;
