import Axios from 'axios';
import { url } from '../../config/config.json';
import { clearUser } from '../storage/user';

const axios = Axios.create({
  baseURL: url,
  responseType: 'json'
});

axios.interceptors.response.use(null as any, (error) => {
  if (error.response.status === 403) {
    clearUser();
    window.location.href = '/login';
  }
  return Promise.reject(error);
});

const setCookie = (name: string, value: string, days = 7, path = '/') => {
  const expires = new Date(Date.now() + days * 864e5).toUTCString();
  document.cookie = name + '=' + encodeURIComponent(value) + '; expires=' + expires + '; path=' + path;
};

// const getCookie = (name) => {
//   return document.cookie.split('; ').reduce((r, v) => {
//     const parts = v.split('=');
//     return parts[0] === name ? decodeURIComponent(parts[1]) : r;
//   }, '');
// };
//
// const deleteCookie = (name, path) => {
//   setCookie(name, '', -1, path);
// };

export const setAuthorization = (token: string) => {
  axios.defaults.headers.common['Authorization'] = token;
  axios.defaults.withCredentials = true;
  setCookie('Authorization', token);
};

export default axios;
