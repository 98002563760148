import {
  IonButton, IonList, IonItem, IonLabel, IonInput, IonRow, IonCol, IonText
} from '@ionic/react';
import React, { FormEvent, useState } from 'react';
import AddFab from './AddFab';
import { postAgency } from '../data/api/agency';
import { fetchBounties } from '../data/agency/agency.actions';
import { connect } from '../data/connect';
import BountyForm from './BountyForm';
import { Agency } from '../models/Agency';
import { getUserAgency } from '../data/selectors';

interface OwnProps {
  children?: React.ReactNode;
  title?: string;
  idAgency: number;
}

interface StateProps {
  agency: number;
}

interface DispatchProps {
  fetchBounties: typeof fetchBounties;
}

const AddBountyFab: React.FC<OwnProps & DispatchProps & StateProps> = ({ fetchBounties, agency, idAgency }) => {

  let closeModal: any = null;
  let cleanBounty: any = null;

  const closeBounty = () => {
    fetchBounties(idAgency);
    closeModal();
    cleanBounty();
  };

  return (
    <AddFab title={'Créer une prime'} close={(close: any) => closeModal = close}>
      <BountyForm formRef={({ clean }) => { cleanBounty = clean; }} onCreate={() => closeBounty()} agency={idAgency || agency}/>
    </AddFab>
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    agency: getUserAgency(state)
  }),
  mapDispatchToProps: {
    fetchBounties
  },
  component: React.memo(AddBountyFab)
});
