import { ActionType } from '../../util/types';
import { Agency } from '../../models/Agency';
import { getAgencies } from '../api/agency';
import { Pagination } from '../../models/Pagination';

export const setLoading = (isLoading: boolean) => ({
  type: 'set-agency-loading',
  isLoading
} as const);

export const setAgencies = (data: { agencies: Agency[]; pagination: Pagination; }) => ({
  type: 'set-agencies',
  data
} as const);

export const addAgencies = (data: {  agencies: Agency[]; pagination: Pagination; }) => ({
  type: 'add-agencies',
  data
} as const);

export const fetchAgencies = (page?: number, truncate = false) => async (dispatch: React.Dispatch<any>) => {
  dispatch(setLoading(true));
  const data = await getAgencies(page);
  dispatch(truncate ? setAgencies(data) : addAgencies(data));
  dispatch(setLoading(false));
};

export type AgenciesActions =
  | ActionType<typeof setLoading>
  | ActionType<typeof addAgencies>
  | ActionType<typeof setAgencies>
