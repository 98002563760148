

export const TimeFormat = (time: number) => {
  // Hours, minutes and seconds
  const hours = ~~(time / 3600);
  const minutes = ~~(time % 3600 / 60);
  const seconds = ~~time % 60;
  return {
    hours,
    minutes,
    seconds
  };
};
