import React, {useRef, useState} from 'react';
import {
  IonItemSliding,
  IonItem,
  IonLabel,
  IonChip, IonAlert, IonItemOptions, IonItemOption, IonIcon
} from '@ionic/react';
import { User } from '../models/User';
import {trash} from "ionicons/icons";

interface UserListItemProps {
  user: User;
  onRemove?: (id: number) => any;
}

type roles = 'Administrateur' | 'Super Administrateur' | 'Conducteur';

enum rolesColor {
  'Administrateur' = 'warning',
  'Super Administrateur' = 'danger',
  'Conducteur' = 'primary'
}

const UserListItem: React.FC<UserListItemProps> = ({ user, onRemove }) => {
  const ionItemSlidingRef = useRef<HTMLIonItemSlidingElement>(null);
  const [showAlert, setShowAlert] = useState(false);

  const dismissAlert = () => {
    ionItemSlidingRef.current && ionItemSlidingRef.current.close();
  };
  return (
    <IonItemSliding ref={ionItemSlidingRef} class={`user-${user.id}`}>
      <IonItem routerLink={`/tabs/user/${user.id}`}>
        <IonLabel>
          <h3>{user.firstname}&nbsp;{user.lastname}</h3>
          <p>
            {user.email}
          </p>
        </IonLabel>
        <IonLabel>
          <IonChip color={rolesColor[user.role.name as roles]}>{user.role.name}</IonChip>
        </IonLabel>
      </IonItem>
      <IonItemOptions slot="end">
        <IonItemOption icon-only color="danger" onClick={() => setShowAlert(true)}>
          <IonIcon slot="icon-only" icon={trash}/>
        </IonItemOption>
      </IonItemOptions>
      <IonAlert
        isOpen={showAlert}
        onDidDismiss={() => setShowAlert(false)}
        header={'Supprimer un utilisateur'}
        message={`Voulez-vous supprimer <strong>${user.firstname}&nbsp;${user.lastname}</strong> ?`}
        buttons={[
          'Annuler', {
            text: 'Supprimer',
            handler: () => onRemove && onRemove(user.id)
          }
        ]}
      />
    </IonItemSliding>
  );
};

export default React.memo(UserListItem);

