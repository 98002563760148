import React, { useEffect, useState } from 'react';
import {
  IonButton,
  IonCol,
  IonContent,
  IonLoading,
  IonPage,
  IonRow,
  IonToast,
} from '@ionic/react';
import { connect } from '../data/connect';
import { RouteComponentProps, withRouter } from 'react-router';
import { getPointing, postSignature } from '../data/api/pointing';
import PointingForm from '../components/PointingForm';
import { getCurrentUser, getPointingState } from '../data/selectors';
import SignatureModal from '../components/SignatureModal';
import { PointingState } from '../util/types';
import { fetchActivities, fetchTransferts } from '../data/agency/agency.actions';
import { fetchPointings } from '../data/pointings/pointings.actions';

type OwnProps = RouteComponentProps

interface StateProps {
  user: any;
  pointingState: PointingState;
}

interface DispatchProps {
  fetchActivities: typeof fetchActivities;
  fetchPointings: typeof fetchPointings;
  fetchTransferts: typeof fetchTransferts;
}

type SessionDetailProps = OwnProps & StateProps & DispatchProps;

const ShareablePointing: React.FC<SessionDetailProps> = ({ match, location, fetchActivities, fetchTransferts }) => {

  const [showLoading, setShowLoading] = useState(false);
  const [showError, setShowError] = useState(false);
  const [unauthorize, setUnauthorize] = useState(false);
  const [state, setState] = useState(0);
  const [message, setMessage] = useState('');

  const [pointingSubmitted, setPointingSubmitted] = useState(false);
  const token = new URLSearchParams(location.search).get('token') || '';
  // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
  // @ts-ignore
  const id = Number(match.params.id);

  let setPointingForm: any = null;
  let pointingForm: any = null;

  useEffect(() => {
    if (isNaN(id)) {
      return setShowLoading(false);
    }
    getPointing(id).then(async (pointing) => {
      if (!pointing) return setShowLoading(false);
      fetchActivities(pointing.agency, true);
      fetchTransferts(pointing.agency, true);
      if (pointing.token !== token) {
        setMessage('Le token de validation n\'est pas valide');
        setUnauthorize(true);
        return setShowError(true);
      }
      const pointingDetails = {
        token: pointing.token,
        date: pointing.date,
        state: Number(pointing.state),
        constructionSitename: pointing.constructionSitename,
        constructionSiteNumber: pointing.constructionSiteNumber,
        activity: pointing.activity.map((activity: any) => ({
          idActivity: Number(activity.id_activity),
          startDate: activity.startDate,
          endDate: activity.endDate
        })),
        bounty: pointing.bountyValue.map((bounty: any) => ({
          id: Number(bounty.id),
          name: bounty.name,
          idBountyValue: Number(bounty.id_bounty_value),
          bountyValues: bounty.bountyValues.map((b: any) => ({ isDefault: b.isDefault === '1', id: b.id, value: b.value }))
        })),
        fuelQuantity: pointing.fuelQuantity,
        fuelProvider: pointing.fuelProvider,
        vehicleName: pointing.vehicleName,
        vehicleNumber: pointing.vehicleNumber,
        validatorName: pointing.validatorName,
        machineName: pointing.machineName,
        machineNumber: pointing.machineNumber,
        comment: pointing.comment,
        validatorEmail: pointing.validatorEmail
      };
      setState(Number(pointing.state));
      setPointingForm(pointingDetails);
    });
  }, []);

  const signPointing = async (token: string, signature: string, state: PointingState) => {
    const result = await postSignature(token, signature, state);
    if (!result) {
      setMessage('Une erreur est survenue');
      return setShowError(true);
    }
    setMessage(state === PointingState.refused ? 'Pointage refusé' : 'Pointage validé');
    return setShowError(true);
  };

  if (unauthorize) {
    return (
      <IonPage>
        <h1 className="ion-text-center">Vous n&apos;avez pas accès à cette ressource</h1>
      </IonPage>
    );
  }

  return (
    <IonPage id="pointing-detail-page">
      <IonContent>
        <PointingForm readOnly actionsDisabled={true} formRef={({ setForm, form }) => { setPointingForm = setForm; pointingForm = form; } }/>
        { state === 1 && !!token &&
        <IonRow>
          <IonCol>
            <IonButton type="submit" expand="block" color="danger" onClick={async () => {
              setShowLoading(true);
              await signPointing(token || pointingForm.token, '', PointingState.refused);
              setShowLoading(false);
              return setState(3);
            }}>Refuser</IonButton>
          </IonCol>
          <IonCol>
            <IonButton type="submit" expand="block" onClick={() => setPointingSubmitted(true)}>Signer</IonButton>
          </IonCol>
        </IonRow>
        }
      </IonContent>
      <SignatureModal isOpen={pointingSubmitted}
        onClose={() => setPointingSubmitted(false)}
        onSave={async (signature) => {
          setShowLoading(true);
          await signPointing(token || pointingForm.token, signature, PointingState.validated);
          setShowLoading(false);
          setState(2);
          return setPointingSubmitted(false);
        }}/>
      <IonToast
        isOpen={showError}
        message={message}
        duration={2000}
        onDidDismiss={() => setShowError(false)}
      />
      <IonLoading
        isOpen={showLoading}
        onDidDismiss={() => setShowLoading(false)}
        message={'Chargement...'}
      />
    </IonPage>
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    user: getCurrentUser(state),
    pointingState: getPointingState(state)
  }),
  mapDispatchToProps: {
    fetchActivities,
    fetchPointings,
    fetchTransferts
  },
  component: withRouter(ShareablePointing)
});
