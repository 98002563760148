import {
  IonItemDivider,
  IonItemGroup,
  IonList,
  IonListHeader,
  IonInfiniteScroll, IonInfiniteScrollContent, IonToast
} from '@ionic/react';
import React, { useState } from 'react';
// import AgencyListItem from './AgencyListItem';
import { Agency } from '../models/Agency';
import { connect } from '../data/connect';
import * as selectors from '../data/selectors';
import AgencyListItem from './AgencyListItem';
import { fetchAgencies } from '../data/agencies/agencies.actions';
import { Pagination } from '../models/Pagination';
import { deleteAgency } from '../data/api/agency';

interface OwnProps {
  disable?: (disable: any) => any;
}

interface StateProps {
  agencyList: { agencies: Agency[]; pagination: Pagination; };
}

interface DispatchProps {
  fetchAgencies: typeof fetchAgencies;
}

interface AgencyListProps extends OwnProps, StateProps, DispatchProps { }

const AgencyList: React.FC<AgencyListProps> = ({ agencyList, disable, fetchAgencies }) => {

  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [disabled, setDisabled] = useState(false);
  if (disable) {
    disable(setDisabled);
  }

  const displayToast = (message: string) => {
    setShowToast(true);
    setToastMessage(message);
  };

  const removeAgency = async (id: number) => {
    const { data: result, status } = await deleteAgency(id);
    if (!result || status !== 200) return displayToast('Une erreur s\'est produite');
    fetchAgencies(1, true);
    return displayToast('L\'agence a bien été supprimée');
  };

  if (!Array.isArray(agencyList.agencies) || agencyList.agencies.length === 0) {
    return (
      <IonList>
        <IonListHeader>
          No Agency Found
        </IonListHeader>
      </IonList>
    );
  }
  return (
    <>
      <IonList>
        {agencyList.agencies.length && agencyList.agencies.map((agency, index: number) => <IonItemGroup key={`agency-${index}`}>
          <IonItemDivider sticky>
            <AgencyListItem onRemove={(id) => removeAgency(id)} agency={agency}/>
          </IonItemDivider>
        </IonItemGroup>)}
      </IonList>
      <IonInfiniteScroll disabled={disabled} onIonInfinite={(e) => {
        const currentPage = agencyList.pagination.currentPage;
        if (agencyList.pagination.totalPages === currentPage) {
          return setDisabled(true);
        }
        fetchAgencies(currentPage + 1);
        setTimeout(() => {
          // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
          // @ts-ignore
          e.target.complete();
        }, 500);
      }}>
        <IonInfiniteScrollContent
          loadingSpinner="bubbles"
          loadingText="Chargement..."
        />
      </IonInfiniteScroll>
      <IonToast
        isOpen={showToast}
        onDidDismiss={() => setShowToast(false)}
        message={toastMessage}
        duration={2000}
      />
    </>
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    agencyList: selectors.getAgencies(state),
  }),
  mapDispatchToProps: {
    fetchAgencies
  },
  component: AgencyList
});
