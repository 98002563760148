import React, { useEffect, useState } from 'react';
import {
  IonHeader,
  IonToolbar,
  IonContent,
  IonPage,
  IonButtons,
  IonBackButton,
  IonList,
  IonButton, IonIcon, IonAlert, IonToast
} from '@ionic/react';
import { connect } from '../data/connect';
import { withRouter, RouteComponentProps } from 'react-router';
import * as selectors from '../data/selectors';
import { User } from '../models/User';
import UserForm from '../components/UserForm';
import { deleteUser, getUser } from '../data/api/user';
import { trash } from 'ionicons/icons';
import { fetchUsers } from '../data/users/users.actions';

type OwnProps = RouteComponentProps;

interface StateProps {
  user?: User;
  currentUser?: any;
}

interface DispatchProps {
  fetchUsers: typeof fetchUsers;
}

type UserDetailsProps = OwnProps & StateProps & DispatchProps & RouteComponentProps;

const UserDetails: React.FC<UserDetailsProps> = ({ match, currentUser, history, fetchUsers }) => {

  let setUserForm: any = null;
  const [userInfos, setUserInfos] = useState<any>(null);
  const [showAlert, setShowAlert] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');

  const displayToast = (message: string) => {
    setToastMessage(message);
    setShowToast(true);
  };

  const removeUser = async (id: number) => {
    const { data: result, status } = await deleteUser(id);
    if (!result || !status) return displayToast('Une erreur s\'est produite');
    fetchUsers(1, true);
    displayToast('L\'utilisateur a bien été supprimé');
    setTimeout(() => {
      history.goBack();
    }, 500);
  };

  const { id } = match.params as any;

  useEffect(() => {
    getUser(id).then(({ data, status }) => {
      if (!status || !data.user) return;
      const { user } = data;
      setTimeout(() => {
        setUserInfos({
          firstname: user.firstname,
          lastname: user.lastname,
        });
        if (setUserForm) setUserForm({
          id,
          firstname: user.firstname,
          lastname: user.lastname,
          email: user.email,
          phone: user.phone,
          carName: user.carName,
          carNumber: user.carNumber,
          machineName: user.machineName,
          machineNumber: user.machineNumber,
        });
      }, 100);
    });
  }, []);

  return (
    <IonPage id="session-detail-page">
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="/tabs/user"></IonBackButton>
          </IonButtons>
          { currentUser && currentUser.role && currentUser.role.id !== 3 &&
          <IonButtons slot="end">
            <IonButton icon-only type="submit" expand="block" color="default" onClick={() => setShowAlert(true)}>
              <IonIcon slot="icon-only" icon={trash} color={'danger'} />
            </IonButton>
          </IonButtons>
          }
        </IonToolbar>
      </IonHeader>
      <IonContent>
        { userInfos &&
        <div className="ion-padding-horizontal ion-text-center">
          <h1><u>{userInfos.firstname} {userInfos.lastname}</u></h1>
        </div> }
        <IonList>
          <UserForm formRef={({ setForm }) => {
            setUserForm = setForm;
          }} update/>
        </IonList>
      </IonContent>
      { userInfos && <IonAlert
        isOpen={showAlert}
        onDidDismiss={() => setShowAlert(false)}
        header={'Supprimer une agence'}
        message={`Voulez-vous supprimer <strong>${userInfos.firstname}&nbsp;${userInfos.lastname}</strong> ?`}
        buttons={[
          'Annuler', {
            text: 'Supprimer',
            handler: () => removeUser(id)
          }
        ]}
      /> }
      <IonToast
        isOpen={showToast}
        onDidDismiss={() => setShowToast(false)}
        message={toastMessage}
        duration={2000}
      />
    </IonPage>
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state, OwnProps) => ({
    user: selectors.getUser(state, OwnProps),
    currentUser: selectors.getCurrentUser(state)
  }),
  mapDispatchToProps: {
    fetchUsers
  },
  component: withRouter(UserDetails)
});
