import {
  IonLoading,
  IonFab,
  IonFabButton,
  IonIcon,
  IonModal,
  IonHeader,
  IonButtons,
  IonToolbar, IonTitle, IonContent, IonButton
} from '@ionic/react';
import { add } from 'ionicons/icons';
import React, { useState } from 'react';

interface AddFabProps {
  children?: React.ReactNode;
  title?: string;
  onClose?: () => any;
  close?: any;
  isOpen?: boolean;
}

const AddFab: React.FC<AddFabProps> = ({ children, title, onClose, close, isOpen }) => {
  const [loadingMessage] = useState('');
  const [showLoading, setShowLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);

  if (isOpen && !showModal) {
    setTimeout(() => {
      setShowModal(isOpen);
    }, 5);
  }

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
    if (onClose) {
      onClose();
    }
  };

  if (close) {
    close(closeModal);
  }

  return (
    <>
      <IonLoading
        isOpen={showLoading}
        message={loadingMessage}
        duration={2000}
        spinner="crescent"
        onDidDismiss={() => setShowLoading(false)}
      />
      <IonFab slot="fixed" vertical="bottom" horizontal="end">
        <IonFabButton onClick={() => openModal()}>
          <IonIcon icon={add} />
        </IonFabButton>
      </IonFab>
      <IonModal isOpen={showModal} onDidDismiss={() => closeModal()}>
        <IonHeader translucent>
          <IonToolbar>
            <IonTitle>{title}</IonTitle>
            <IonButtons color={'danger'} slot="end" onClick={() => closeModal()}>
              <IonButton>
                Fermer
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent fullscreen>
          {children}
        </IonContent>
      </IonModal>
    </>
  );
};

export default React.memo(AddFab);
