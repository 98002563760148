import React, { useState, useRef, useEffect } from 'react';
import {
  IonToolbar,
  IonContent,
  IonPage,
  IonButtons,
  IonMenuButton,
  IonSearchbar,
  IonRefresher,
  IonRefresherContent,
  IonToast,
  IonHeader,
  IonButton, IonIcon, IonModal, IonTitle, IonRow, IonCol, IonLoading, IonBackButton
} from '@ionic/react';
import { connect } from '../data/connect';
import { fetchUsers } from '../data/users/users.actions';
import AddUserFab from '../components/AddUserFab';
import UserList from '../components/UserList';
import { RouteComponentProps, withRouter } from 'react-router';
import { cloudUpload } from 'ionicons/icons';
import { postUsers } from '../data/api/user';
import { getAgency } from '../data/api/agency';

interface OwnProps { }

interface StateProps {
  // sessionGroups: SessionGroup[];
  // favoriteGroups: SessionGroup[];
  role: any;
}

interface DispatchProps {
  fetchUsers: typeof fetchUsers;
}

type UserPageProps = OwnProps & StateProps & DispatchProps & RouteComponentProps;

const UserPage: React.FC<UserPageProps> = ({ fetchUsers, location, role }) => {
  const ionRefresherRef = useRef<HTMLIonRefresherElement>(null);
  const [showCompleteToast, setShowCompleteToast] = useState(false);
  const [uploadModal, setUploadModal] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [toastText, setToastText] = useState('');
  const [agencyName, setAgencyName] = useState('');
  const [fileUpload, setFileUpload] = useState<File | null>(null);
  const idAgency = Number(new URLSearchParams(location.search).get('agency')) || 0;
  let setDisable: any = null;

  useEffect(() => {
    if (idAgency) {
      getAgency(idAgency).then(({ agence }) => {
        setAgencyName(agence.name || '');
      });
    }
    setTimeout(() => {
      fetchUsers(1, true, idAgency);
    }, 250);
  }, []);

  const doRefresh = () => {
    fetchUsers(1, true, idAgency);
    setTimeout(() => {
      ionRefresherRef.current!.complete();
      setShowCompleteToast(true);
      setDisable(false);
    }, 1000);
  };

  const saveFile = (e: any) => {
    const file = e.target.files[0];
    if (!file) return;
    setFileUpload(e.target.files[0]);
  };

  const uploadUsers = async () => {
    setShowLoading(true);
    const formData = new FormData();
    if (!fileUpload) return;
    formData.append('file', fileUpload);
    const result = await postUsers(formData);
    if (result.status && (result.status !== true || typeof result.status === 'number' && result.status !== 200)) {
      setShowLoading(false);
      setToastText('Une erreur est survenue');
      return setShowToast(true);
    }
    setToastText(result.data.alert.message);
    setShowToast(true);
    setShowLoading(false);
  };

  return (
    <IonPage id="agency-page">
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            { role && role.id === 1 ?
              <IonBackButton defaultHref={`/tabs/agency/${idAgency}`}/> :
              <IonMenuButton />
            }
          </IonButtons>
          <IonButtons slot="end">
            <IonButton icon-only onClick={() => setUploadModal(true)}>
              <IonIcon color="primary" slot="icon-only" icon={cloudUpload}/>
            </IonButton>
          </IonButtons>
          <IonTitle>
            Utilisateurs { agencyName ? `de ${agencyName}` : ''}
          </IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonToolbar>
        <IonSearchbar
          placeholder="Rechercher"
          debounce={300}
          onIonChange={(e: CustomEvent) => {
            fetchUsers(1, true, idAgency, 0, e.detail.value!);
          }}
        />
      </IonToolbar>

      <IonContent>
        <IonRefresher slot="fixed" ref={ionRefresherRef} onIonRefresh={doRefresh}>
          <IonRefresherContent />
        </IonRefresher>
        <IonToast
          isOpen={showCompleteToast}
          message="Contenu rafraichi"
          duration={2000}
          onDidDismiss={() => setShowCompleteToast(false)}
        />

        <UserList idAgency={idAgency} disable={(disable: any) => setDisable = disable}/>
      </IonContent>
      <AddUserFab idAgency={idAgency}/>
      <IonModal isOpen={uploadModal} onDidDismiss={() => setUploadModal(false)}>
        <IonHeader translucent>
          <IonToolbar>
            <IonTitle>Importer des utilisateurs</IonTitle>
            <IonButtons color={'danger'} slot="end" onClick={() => setUploadModal(false)}>
              <IonButton>
                Fermer
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent fullscreen>
          <div>
          </div>
          <div className="ion-padding-horizontal">
            <div className="ion-padding">
              <input type="file" onChange={(e) => saveFile(e)}/>
            </div>
            <b>Le fichier importé doit être une fichier csv et doit respecter une structure bien définie.</b>
            <p>La première ligne du fichier est ignoré. Elle sert d&apos;en-tête pour définir les colonnes.</p>
            <p>Ordre des colonnes :</p>
            <ol>
              <li>Adresse mail</li>
              <li>Prénom</li>
              <li>Nom</li>
              <li>N° de téléphone</li>
              <li>Mot de passe</li>
              <li>Nom de l&apos;agence</li>
            </ol>
            <b>Une colonne non renseignée sera ignoré lors du traitement des données.</b>
            <p>Vous trouverez un exemple de fichier CSV valide ici : <a target="_blank" rel="noopener noreferrer" href="/assets/import_example.csv">Télécharger</a></p>
          </div>
        </IonContent>
        { fileUpload && <IonRow class="ion-padding">
          <IonCol>
            <IonButton type="submit" expand="block" onClick={() => uploadUsers()}>Importer</IonButton>
          </IonCol>
        </IonRow>}
        <IonLoading
          isOpen={showLoading}
          message={'Importation...'}
        />
        <IonToast
          isOpen={showToast}
          message={toastText}
          duration={2000}
          onDidDismiss={() => setShowToast(false)}
        />
      </IonModal>
    </IonPage>
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    role: state.user.role
  }),
  mapDispatchToProps: {
    fetchUsers
  },
  component: withRouter(UserPage)
});
