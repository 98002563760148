import React, { useState, useRef, useEffect } from 'react';
import {
  IonToolbar,
  IonContent,
  IonPage,
  IonButtons,
  IonMenuButton,
  IonRefresher,
  IonRefresherContent,
  IonToast,
  IonHeader,
  IonBackButton,
  IonTitle
} from '@ionic/react';
import { connect } from '../data/connect';
import NotificationList from '../components/NotificationList';
import { getNotifications, getUserAgency } from '../data/selectors';
import { fetchNotifications } from '../data/agency/agency.actions';
import { Notification } from '../models/Notification';
import { RouteComponentProps, withRouter } from 'react-router';
import { getAgency } from '../data/api/agency';

interface OwnProps { }

interface StateProps {
  notifications: Notification[];
  agency: number;
  role: any;
}

interface DispatchProps {
  fetchNotifications: typeof fetchNotifications;
}

type NotificationPageProps = OwnProps & StateProps & DispatchProps & RouteComponentProps;

const NotificationPage: React.FC<NotificationPageProps> = ({ fetchNotifications, notifications, agency, location, role }) => {
  const ionRefresherRef = useRef<HTMLIonRefresherElement>(null);
  const [showCompleteToast, setShowCompleteToast] = useState(false);
  const [agencyName, setAgencyName] = useState('');
  const idAgency = Number(new URLSearchParams(location.search).get('agency')) || 0;

  useEffect(() => {
    if (idAgency) {
      getAgency(idAgency).then(({ agence }) => {
        setAgencyName(agence.name || '');
      });
    }
    fetchNotifications(idAgency);
  }, []);

  const doRefresh = () => {
    fetchNotifications(idAgency);
    setTimeout(() => {
      ionRefresherRef.current!.complete();
      setShowCompleteToast(true);
    }, 1000);
  };

  return (
    <IonPage id="pointing-page">
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            { role && role.id === 1 ?
              <IonBackButton defaultHref={`/tabs/agency/${idAgency}`}/> :
              <IonMenuButton />
            }
          </IonButtons>
          <IonTitle>
            Notification {agencyName ? `de ${agencyName}` : ''}
          </IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent>
        <IonRefresher slot="fixed" ref={ionRefresherRef} onIonRefresh={doRefresh}>
          <IonRefresherContent />
        </IonRefresher>
        <IonToast
          isOpen={showCompleteToast}
          message="Contenu rafraichi"
          duration={2000}
          onDidDismiss={() => setShowCompleteToast(false)}
        />
        <NotificationList idAgency={idAgency || agency} notificationList={notifications}/>
      </IonContent>
    </IonPage>
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    agency: getUserAgency(state),
    notifications: getNotifications(state) as any,
    role: state.user.role,
  }),
  mapDispatchToProps: {
    fetchNotifications
  },
  component: withRouter(NotificationPage)
});
