import axios from '../axios';

export interface Agency {
  name: string;
}

export const postAgency = (data: Agency) => axios.post('/agence/register', data);

export const getAgencies = async (page?: number) => {
  const { data: result } = await axios.get('/agence/findAll', {
    params: {
      page: page || 1,
      limit: 15
    }
  });
  const agencies = result.data.agences || [];
  const pagination = result.data.pagination || {
    totalPages: 0,
    currentPage: 0,
    totalRow: 0
  };
  return {
    agencies,
    pagination
  };
};

export const getAgency = async (id: number) => {
  try {
    const { data: result } = await axios.get(`/agence/retrieve/${id}`);
    return result.data;
  } catch (e) {
    return e.response;
  }
};

export const deleteAgency = async (id: number) => {
  try {
    return await axios.delete(`/agence/remove/${id}`);
  } catch (e) {
    return e.response;
  }
};

export const updateAgency = async (id: number, name: string) => {
  try {
    return await axios.put(`/agence/update/${id}`, {
      name
    });
  } catch (e) {
    return e.response;
  }
};
