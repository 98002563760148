import { AgenciesActions } from './agencies.actions';
import { AgenciesState } from './agencies.state';

export const agenciesReducer = (state: AgenciesState, action: AgenciesActions): AgenciesState => {
  switch (action.type) {
    case 'set-agency-loading': {
      return { ...state, loading: action.isLoading };
    }
    case 'set-agencies':
      return { ...state, data: action.data } as any;
    case 'add-agencies':
      return {
        ...state,
        data: {
          agencies: [...state.data.agencies, ...action.data.agencies],
          pagination: action.data.pagination
        }
      };
  }
};
