import axios from '../axios';

export const postBounty = async (idAgency: number, data: any) => {
  try {
    return await axios.post(`/agence/addBounty/${idAgency}`, { ...data });
  } catch (e) {
    return e.response;
  }
};

export const deleteBounty = async (idBounty: number) => {
  try {
    return await axios.delete(`/agence/removeBounty/${idBounty}`);
  } catch (e) {
    return e.response;
  }
};

export const getBounties = async (id: number) => {
  const { data: result } = await axios.get(`/agence/findAllBounty/${id}`, {
    params: {
      page: 1,
      limit: 1000
    }
  });
  const bounties = result.data.bounties[1].map((bounty: any) => ({
    ...bounty,
    bountyValues: bounty.bountyValue.map((value: any) => ({
      ...value,
      isDefault: Boolean(value.isDefault === '1')
    }))
  }));
  return bounties;
};
