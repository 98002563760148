import axios from '../axios';
import Storage from '../../storage';

const ROLES = 'roles';

export const getRoles = async () => {
  const rolesStorage = await Storage.get({ key: ROLES });
  const roles = JSON.parse(await rolesStorage.value || '[]');
  if (roles.length) {
    return roles;
  }

  const { data: result } = await axios.get('/role/findAll', {
    params: {
      page: 1,
      limit: 1000
    }
  });
  if (!result) {
    throw new Error('cannot fetch roles');
  }

  // TODO: repair roles
  await Storage.set({ key: ROLES, value: JSON.stringify(result.data.roles[1].map(({ id, name }: any) => ({
    id,
    name
  }))) });
  return result.data.roles[1];
};
