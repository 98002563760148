import { PointingsActions } from './pointings.actions';
import { PointingsState } from './pointings.state';

export const pointingsReducer = (state: PointingsState, action: PointingsActions): PointingsState => {
  switch (action.type) {
    case 'set-pointing-loading': {
      return { ...state, loading: action.isLoading };
    }
    case 'set-pointing-state': {
      return { ...state, state: action.state };
    }
    case 'set-pointings':
      return { ...state, data: action.data } as any;
    case 'add-pointings':
      return {
        ...state,
        data: {
          pointings: [...state.data.pointings, ...action.data.pointings],
          pagination: action.data.pagination
        }
      };
  }
};
